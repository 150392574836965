<template>
  <HeadlessCombobox v-model="filter.countryIds" multiple>
    <AuctionButtonsContainer static>
      <AuctionListFilterButton
        v-for="country in filterData.countries"
        :key="country"
        :selected="filter.countryIds.includes(country.id)"
        :value="country.id"
      >
        <Icon mode="svg" :name="`cif:${country.isoCode.toLowerCase()}`" />
        {{ country.name }}
      </AuctionListFilterButton>
    </AuctionButtonsContainer>
  </HeadlessCombobox>
</template>

<script setup lang="ts">
import type { Ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useAuctionStore } from '@autobid/nuxt-pinia-store/store/useAuctionStore'
import type { ParsedFilterData } from '@autobid/strapi-integration/typescript/FilterData'
import AuctionButtonsContainer from './AuctionButtonsContainer.vue'
import AuctionListFilterButton from './AuctionListFilterButton.vue'

const auctionStoreKey = inject('auctionStoreKey')
const { filter } = storeToRefs(useAuctionStore(auctionStoreKey))

const filterData = inject('filterData') as Ref<ParsedFilterData>
</script>
