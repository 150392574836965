import { defineStore } from 'pinia'
import type { Option } from '@autobid/ui/types/Dictionary'
import type {
  CarsListMetaData,
  StoreCars
} from '@autobid/ui/types/components/CarsList'
import { removeReference } from '@autobid/strapi-integration/utils/helpers'
import type { LocationQuery } from 'vue-router'
import type { AuctionCarsVariables } from '@autobid/ui/composables/useFetchCars'
import { useFetchCars } from '@autobid/ui/composables/useFetchCars'
import type { AuctionItemListType } from '@autobid/strapi-integration/typescript/strapi/dynamic/DynamicAuctionItemList'
import { useRuntimeConfig, showError } from 'nuxt/app'
import { ref, computed } from 'vue'
import type { AuctionCar, CarsListCar } from '@autobid/ui/types/Car'
import type {
  SortingType,
  Filters,
  CachedFilters,
  FetchCarProps,
  FilterChangeProp
} from '@autobid/nuxt-pinia-store/types/Cars'
import { getDefaultFilterState } from './carsStore/getDefaultFilterState'
import { getFilters } from './carsStore/getFilters'
import { useGetCarIds } from './carsStore/getCarIds'
import { updateUrlParams } from './carsStore/updateUrlParams'

export const useCarsStore = defineStore('useCars', () => {
  const sortingType = ref<SortingType>('catalogNumber-ASCENDING')
  const carIds = ref<number[]>()
  const filters = ref<Filters>(getDefaultFilterState())
  const cachedFilters = ref<Record<string, CachedFilters>>({})
  const filtersTabActive = ref(false)
  const carsData = ref<StoreCars | null>(null)
  const noFilterMetadata = ref<CarsListMetaData>()
  const displayingCarsIds = ref<number[]>([])
  const loadingNewCars = ref(false)
  const { locale } = useI18n()
  const { APP_ID } = useRuntimeConfig().public
  const carsPerPage = ref(30)
  const auctionId = ref(0)
  const listType = ref<AuctionItemListType>()
  const includeFiltersInUrl = ref(false)
  const router = useRouter()
  const { getCarIds } = useGetCarIds()
  const { fetchCars } = useFetchCars()

  const filtersQty = computed(() =>
    Object.values(filters.value)
      .map((el) => Object.keys(el).length)
      .reduce((a, b) => a + b, 0)
  )

  const handleFilterChange = async ({
    isAdding,
    value,
    fieldName,
    label,
    singular,
    omitFetch
  }: FilterChangeProp) => {
    const hasSuppliers =
      carsData.value?.metadata?.counters?.supplier !== undefined

    if (singular) {
      filters.value[fieldName] = isAdding ? { [value]: label } : {}
    } else if (isAdding) {
      filters.value[fieldName][value] = label
    } else {
      delete filters.value[fieldName][value]
    }

    if (omitFetch) return

    await fetchAuctionCars({
      resetPagination: true,
      graphqlQueryOmits: {
        noFilterMetadata: true,
        suppliers: !hasSuppliers
      }
    })
  }

  const areAllChecked = (values: Omit<Option, 'types'>[], valueKey: string) => {
    if (!values.length) return false

    return values.every(
      ({ id }) => filters.value[valueKey as keyof typeof filters.value][id]
    )
  }

  const toggleAllValuesOfFilter = (
    values: Omit<Option, 'types'>[],
    valueKey: string
  ) => {
    const newValue = removeReference(
      filters.value[valueKey as keyof typeof filters.value]
    )
    const allChecked = areAllChecked(values, valueKey)

    if (allChecked) {
      values.forEach(({ id }) => {
        delete newValue[id]
      })
    } else {
      values.forEach(({ id, label }) => {
        newValue[id] = label
      })
    }

    filters.value[valueKey as keyof typeof filters.value] = newValue

    fetchAuctionCars({
      resetPagination: true,
      graphqlQueryOmits: {
        noFilterMetadata: true
      }
    })
  }

  const resetFilters = (skipFetch?: boolean) => {
    filters.value = getDefaultFilterState()

    if (!skipFetch) {
      fetchAuctionCars({
        resetPagination: true,
        graphqlQueryOmits: {
          noFilterMetadata: true
        }
      })
    }
  }

  const handleAnotherAuction = () => {
    displayingCarsIds.value = []
    carsData.value = {
      itemCount: 0,
      itemPageCount: 0,
      items: {},
      metadata: {}
    }
    carIds.value = []
    cachedFilters.value = {}
  }

  const resetAuctionId = () => {
    auctionId.value = 0
  }

  const setCachedCars = (cacheKey: string) => {
    // timeout to change the opacity class first and later unmount cars
    if (!carsData.value) return

    const cachedData = cachedFilters.value[cacheKey]
    displayingCarsIds.value = cachedData.carIds

    carsData.value = {
      ...(carsData.value as StoreCars),
      itemPageCount: cachedData.itemPageCount,
      metadata: cachedData.metadata
    }

    loadingNewCars.value = false
  }

  const prepareFiltersToFetch = (): AuctionCarsVariables => {
    if (listType.value === 'carSearch') {
      resetAuctionId()
    }

    return getFilters({
      filters: filters.value,
      sortingType: sortingType.value,
      carIds: carIds.value,
      listType: listType.value,
      auctionId: auctionId.value,
      pageSize: carsPerPage.value,
      appId: Number(APP_ID),
      currentPage: router.currentRoute.value.query.currentPage,
      lang: locale.value
    })
  }

  const setCarIds = async (query: LocationQuery) => {
    handleAnotherAuction()

    carIds.value = await getCarIds(query)
  }

  const fetchAuctionCars = async (props?: FetchCarProps) => {
    const name = router.currentRoute.value.name
    const isCarPage =
      name.startsWith('auction-current-slug') || name.startsWith('item-slug')

    loadingNewCars.value = true

    if (props?.getByIds && props.query && !carIds.value?.length) {
      await setCarIds(props.query)
    }

    if (props?.itemsPerPage) {
      carsPerPage.value = props.itemsPerPage
    }

    if (!isCarPage && !props?.preventUrlUpdate) {
      await updateUrlParams({
        ...(includeFiltersInUrl.value
          ? {
              filters: filters.value,
              sortingType: sortingType.value
            }
          : {}),
        currentPage: props?.resetPagination
          ? '1'
          : String(router.currentRoute.value.query.currentPage ?? 1),
        route: router.currentRoute.value,
        router
      })
    }

    const activeFilters = prepareFiltersToFetch()
    const cacheKey = JSON.stringify(activeFilters)

    if (cachedFilters.value[cacheKey]) {
      setCachedCars(cacheKey)

      return carsData.value
    }

    const resp = await fetchCars(activeFilters, {
      ...(props?.graphqlQueryOmits ?? {}),
      noFilterMetadataFinishedCars: listType.value === 'carSearch'
    })

    if (!resp) {
      loadingNewCars.value = false
      return
    }

    if (resp.errors) {
      loadingNewCars.value = false

      throw showError({
        statusCode: resp.errors[0].status_code,
        statusMessage: resp.errors[0].message
      })
    }

    if (resp.data.meta) {
      noFilterMetadata.value = resp.data.meta.metadata
    }

    const { items: newCars, itemCount, itemPageCount } = resp?.data.items ?? {}
    const respMetadata = resp?.data?.items?.metadata
    const {
      itemCount: currentItemCount,
      itemPageCount: currentItemPageCount,
      metadata: currentMetadata
    } = carsData.value ?? {}

    const newCarsIds = newCars.map(({ id }) => id)

    displayingCarsIds.value = newCarsIds

    const newCarsKeyedById = newCars.reduce(
      (acc: Record<number, AuctionCar>, newCar) => {
        const currentCarData =
          carsData.value?.items?.[+newCar.id] ?? ({} as CarsListCar)

        /**
         * due to the optimization purpose, we fetch only one bid for cars list, but in case of barometer we need 11 bids to render barometer correctly. Therefore we can't allow to save bids by a cars list fetcher if there are more bids than was before
         */
        const bids = (() => {
          if (
            'bids' in currentCarData &&
            currentCarData.bids.length > (newCar.bids?.length || 0)
          ) {
            return currentCarData.bids
          }

          return newCar.bids
        })()

        acc[+newCar.id] = {
          ...currentCarData,
          ...newCar,
          bids
        } as AuctionCar
        return acc
      },
      {}
    )

    carsData.value = {
      items: {
        ...(carsData.value?.items ?? {}),
        ...newCarsKeyedById
      },
      itemCount: itemCount ?? currentItemCount,
      itemPageCount: itemPageCount ?? currentItemPageCount,
      metadata:
        respMetadata && ('counters' in respMetadata || 'errors' in respMetadata)
          ? respMetadata
          : currentMetadata ?? {}
    }

    cachedFilters.value[cacheKey] = {
      itemPageCount: carsData.value.itemPageCount,
      carIds: newCarsIds,
      metadata: carsData.value.metadata
    }

    loadingNewCars.value = false

    return carsData.value
  }

  return {
    sortingType,
    filters,
    filtersTabActive,
    filtersQty,
    carsData,
    noFilterMetadata,
    displayingCarsIds,
    loadingNewCars,
    auctionId,
    cachedFilters,
    listType,
    carIds,
    includeFiltersInUrl,
    carsPerPage,
    handleFilterChange,
    areAllChecked,
    toggleAllValuesOfFilter,
    resetFilters,
    fetchAuctionCars,
    handleAnotherAuction
  }
})
