<template>
  <ClientOnly>
    <div class="fixed bottom-2 right-2 z-[99]">
      <CommonTooltip
        show-on-mobile
        :tooltip="
          socketConnected
            ? 'Websockets connected'
            : `Websockets disconnected (${
                socketDisconnectReason ?? 'Unknown reason'
              })`
        "
      >
        <div
          class="h-3 w-3 rounded-full"
          :class="{
            'bg-red': !socketConnected,
            'bg-success': socketConnected
          }"
        ></div>
      </CommonTooltip>
    </div>
  </ClientOnly>
</template>

<script lang="ts" setup>
import { useSocketData } from '@autobid/web-socket/src/helper'

const { socketConnected, socketDisconnectReason } = useSocketData()
</script>
