<template>
  <div>
    <div v-if="pageData || allowEmpty" class="page">
      <slot :page-data="pageData" />
    </div>

    <client-only>
      <CommonDialogTerms
        v-if="isAuthed && pageData?.page?.attributes.termsRequired"
      />
    </client-only>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, onServerPrefetch, watch } from 'vue'
import { useQuery } from '@tanstack/vue-query'
import { useAutobidAuth } from '@autobid/nuxt-auth/src/composables/useAutobidAuth'
import type { StrapiPageError } from '@autobid/strapi-integration/typescript/strapi/Page'
import { hasUserAccessToThisPage } from '@autobid/strapi-integration/utils/helpers'
import { useStrapiLang } from '@autobid/strapi-integration/composable/useStrapiLang'
import {
  usePage,
  type PageProps
} from '@autobid/strapi-integration/composable/usePage'

const emit = defineEmits(['error'])
const props = defineProps<PageProps>()

const { strapiLang: locale } = useStrapiLang()
const slug = props.slug.startsWith('/')
  ? props.slug
  : `/${locale.value}/${props.slug}`
const { isAuthed, setPageRedirectData } = useAutobidAuth()
const { t } = useI18n()
const nuxtApp = useNuxtApp()

const { getPageQueryKey, fetchPage } = usePage()
const { data, suspense, isLoading } = useQuery(
  getPageQueryKey(props, slug),
  async () => await fetchPage(props, slug),
  {
    refetchOnWindowFocus: false
  }
)
onServerPrefetch(suspense)

const pageData = computed(() =>
  'redirection' in (data.value ?? {}) ? null : data.value
)
const pageMiddleware = () => {
  if (!data.value && !isLoading.value) {
    emit('error', {
      code: 404,
      message: t('error-page.404.description')
    } as StrapiPageError)
    return
  }

  const redirection = data.value?.redirection
  if ('redirection' in (data.value ?? {}) && redirection) {
    return nuxtApp.runWithContext(() =>
      navigateTo(redirection.to, {
        redirectCode: redirection.code,
        replace: true
      })
    )
  }

  const page = data.value?.page
  if (!page) return

  const hasAccess = hasUserAccessToThisPage(
    page.attributes.access,
    isAuthed.value
  )
  if (!hasAccess) {
    if (isAuthed.value) {
      return navigateTo(`/${locale.value}`)
    } else {
      emit('error', {
        code: 401,
        message: t('error-page.401.description')
      })
    }
  }
}

const updatePageRedirectData = () => {
  if (!pageData.value?.page) return

  const willBeAccessAfterAuthChange = hasUserAccessToThisPage(
    pageData.value.page.attributes.access,
    !isAuthed.value
  )

  setPageRedirectData({ redirectable: willBeAccessAfterAuthChange })
}
onMounted(() => {
  updatePageRedirectData()
})

const fetchOnClient = async () => {
  // function to await in a top level component to avoid content shifts
  if (data.value) {
    return
  }

  await suspense()
}

await fetchOnClient()

pageMiddleware()

watch(data, () => {
  pageMiddleware()
})
</script>
