<template>
  <div v-if="visibleButtons.length" class="px-3 xl:pl-0">
    <p
      v-if="data.title"
      :class="[
        'font-bold',
        buttonActions({
          alignedContent: data.alignedContent?.name
        })
      ]"
      data-testid="button-actions-title"
    >
      {{ data.title }}
    </p>

    <div
      class="buttons-actions flex flex-wrap items-center gap-1"
      :class="
        buttonActions({
          layout: data.layout,
          alignedButtons: data.alignedContent?.name
        })
      "
      data-testid="button-actions-btns"
    >
      <elements-button
        v-for="button in visibleButtons"
        :key="button.url"
        v-bind="button"
      >
        {{ button.text }}
      </elements-button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { StrapiSectionsButtonActions } from '@autobid/strapi-integration/typescript/strapi/sections/SectionsButtonActions'
import type { StrapiComponentProps } from '@autobid/strapi-integration/typescript/strapi/Strapi'
import { buttonActions } from '@autobid/ui/utils/cva/buttonActionsCva'
import { computed } from 'vue'

interface Props {
  data: StrapiComponentProps<StrapiSectionsButtonActions>
}

const props = defineProps<Props>()

const visibleButtons = computed(() =>
  props.data.buttons.filter((el) => !el.hidden)
)
</script>
