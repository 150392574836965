<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    class="rich-cke prose max-w-full px-3 xl:pl-0"
    data-testid="rich-cke"
    :class="[richTextCkeditorCva({ width: data.width })]"
    v-html="formatContent(data.content)"
  ></div>
</template>

<script lang="ts" setup>
import type { StrapiSectionRichTextCkeditor } from '@autobid/strapi-integration/typescript/strapi/sections/SectionRichTextCkeditor'
import type { StrapiComponentProps } from '@autobid/strapi-integration/typescript/strapi/Strapi'
import { richTextCkeditorCva } from '@autobid/ui/utils/cva/richTextCkeditorCva'
import { useCkeditor } from '@autobid/ui/composables/useCkeditor'

interface Props {
  data: StrapiComponentProps<StrapiSectionRichTextCkeditor>
}

defineProps<Props>()

const { formatContent } = useCkeditor()
</script>

<style lang="scss">
@use './style.scss';
</style>
