<template>
  <div
    class="flex h-full w-full flex-col items-center justify-center bg-white p-2 group-hover:bg-slate-100"
  >
    <span v-if="stage !== 'IN_PREPARATION'" class="text-4xl">{{ count }}</span>
    <Designations v-if="designations.length" :designations="designations" />
    <Icon
      v-if="nationality !== 'NATIONAL' && country?.isoCode"
      mode="svg"
      :name="`cif:${country.isoCode.toLowerCase()}`"
      class="h-[40px] w-[60px] self-start"
      :title="country.name"
    />
  </div>
</template>

<script setup lang="ts">
import type { DisplayingAuction } from '@autobid/ui/types/components/AuctionsData'
import Designations from './element/Designations.vue'

const { count, designations, country, nationality, stage } = inject(
  'auction'
) as DisplayingAuction
</script>
