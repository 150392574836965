<template>
  <HeadlessCombobox
    v-slot="{ open }"
    :multiple="multiple"
    :model-value="value ? value : multiple ? [] : ''"
    @update:model-value="onChange"
  >
    <HeadlessComboboxInput
      v-if="!disableSearch"
      :id="inputId"
      ref="input"
      :placeholder="open || isInputFocused ? getDisplayValues() : placeholder"
      class="z-50 h-full w-full overflow-hidden text-ellipsis rounded-md border-none py-2.5 pl-3 text-left text-sm text-primary placeholder:opacity-50 focus:ring-0"
      :display-value="
        (open && multiple) || isInputFocused ? () => '' : getDisplayValues
      "
      :title="getDisplayValues()"
      :aria-required="required ? 'true' : 'false'"
      @change="query = $event.target.value"
      @focus="isInputFocused = true"
      @blur="isInputFocused = false"
    />
    <HeadlessComboboxButton
      class="absolute inset-y-0 right-0 flex items-center pr-2"
      :aria-label="$t('toggle')"
    >
      <Icon
        mode="svg"
        class="h-8 w-8 text-primary transition"
        aria-hidden="true"
        name="mdi:chevron-down"
        :class="open && 'rotate-180'"
      />
    </HeadlessComboboxButton>
    <button
      v-if="
        Array.isArray(value) || typeof value === 'string'
          ? value.length
          : value !== undefined
      "
      type="button"
      class="absolute inset-y-0 right-10 z-10 flex items-center"
      @click="
        (e) => {
          onChange(undefined)
          onClear?.(e)
        }
      "
    >
      <Icon
        mode="svg"
        class="h-8 w-8 text-primary transition"
        aria-hidden="true"
        name="uil:times"
        :aria-label="$t('clear')"
      />
    </button>
    <div :class="disableSearch ? 'h-full' : ''">
      <div
        ref="wrapper"
        class="relative h-full w-full cursor-default overflow-hidden sm:text-sm"
      >
        <HeadlessComboboxButton
          v-if="disableSearch"
          :id="inputId"
          class="z-50 block h-full w-full overflow-hidden text-ellipsis rounded-md border-none py-2.5 pl-3 text-left text-sm text-primary placeholder:opacity-50 focus:ring-0"
          @focus="isInputFocused = true"
          @blur="isInputFocused = false"
        >
          <span :class="!getDisplayValues() && 'opacity-50'">
            {{ getDisplayValues() ?? placeholder ?? '‎' }}
          </span>
        </HeadlessComboboxButton>
      </div>
      <HeadlessTransitionRoot
        leave="transition ease-in duration-100"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <HeadlessComboboxOptions
          class="select-options absolute z-50 mt-1 max-h-60 rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
          :style="{
            width: `${wrapper?.clientWidth || 0}px`
          }"
          @vue:before-unmount="query = ''"
        >
          <div
            v-if="Array.isArray(value) && value?.length && multiple"
            data-testid="values"
            class="sticky flex w-full flex-wrap items-center gap-1 bg-white px-4 py-2 text-primary shadow"
          >
            <span
              v-for="item in valueCard"
              :key="item"
              class="rounded-sm bg-primary p-1 text-white"
            >
              {{ item }}
            </span>
          </div>
          <div
            v-if="
              !notHighlighted?.length && !highlighted.length && query.length
            "
            class="relative w-full cursor-default select-none px-4 py-2 text-gray-700"
          >
            {{ $t('search-form.nothing-found') }}
          </div>
          <div v-else class="h-full max-h-[12.25rem] overflow-y-auto bg-white">
            <template v-if="highlighted.length">
              <HeadlessComboboxOption
                v-for="option in highlighted"
                :key="`highlighted=${option}`"
                v-slot="{ selected: _selected, active }"
                as="template"
                :value="typeof option !== 'object' ? option : String(option.id)"
              >
                <SelectOptionInner
                  :option="option"
                  :selected="_selected"
                  :active="active"
                />
              </HeadlessComboboxOption>
              <div v-if="notHighlighted.length" class="py-2">
                <div class="h-[2px] w-full bg-slate-100"></div>
              </div>
            </template>
            <HeadlessComboboxOption
              v-for="option in notHighlighted"
              :key="JSON.stringify(option)"
              v-slot="{ selected: _selected, active }"
              as="template"
              :value="typeof option !== 'object' ? option : String(option.id)"
            >
              <SelectOptionInner
                :option="option"
                :selected="_selected"
                :active="active"
              />
            </HeadlessComboboxOption>
          </div>
        </HeadlessComboboxOptions>
      </HeadlessTransitionRoot>
    </div>
  </HeadlessCombobox>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import type {
  SelectOptions,
  SelectOption
} from '@autobid/ui/types/components/Select'
import SelectOptionInner from './SelectOptionInner.vue'

interface Props {
  value?: string[] | number[] | number | string
  options?: SelectOptions
  placeholder?: string
  multiple?: boolean
  onClear?: (e: Event) => void
  inputId?: string
  fullLengthDisplayValue?: boolean
  disableSearch?: boolean
  required?: boolean
}

const props = defineProps<Props>()

const query = ref('')
const wrapper = ref<HTMLDivElement>()
const input = ref<{ el: HTMLInputElement }>()

const isInputFocused = ref(false)

const filterOptionsByTerm = (options: SelectOptions) => {
  return options.filter((option) => {
    const lowerTrimmedQuery = query.value.toLowerCase().trim()

    if (typeof option !== 'object') {
      return option.toString().toLowerCase().includes(lowerTrimmedQuery)
    }
    if ('label' in option) {
      return option.label.toLowerCase().includes(lowerTrimmedQuery)
    }
    return option.name.toLowerCase().includes(lowerTrimmedQuery)
  })
}

const emits = defineEmits<{ (e: 'change', value: SelectOptions): void }>()

const onChange = (e: SelectOptions) => {
  emits('change', e)
  if (props.multiple || props.disableSearch) {
    return
  }

  nextTick(() => {
    setTimeout(() => {
      input.value?.el.blur()
    })
  })
}

const { t } = useI18n()

function getOptionDisplayValue(option: SelectOption) {
  if (typeof option === 'string' || typeof option === 'number') {
    return option
  }
  if ('label' in option) {
    return option.label
  }
  return option.name
}

provide('getOptionDisplayValue', getOptionDisplayValue)

const getDisplayValues = () => {
  if (!props.value) {
    return
  }

  if (!Array.isArray(props.value)) {
    const option = props.options?.find((item) => {
      if (typeof item !== 'object') {
        return item === props.value
      }
      return item.id === props.value
    })
    if (!option) {
      return ''
    }
    return getOptionDisplayValue(option)?.toString()
  }

  const displayOptions = props.value
    .map((item) => {
      const option = props.options?.find((option) => {
        if (typeof option !== 'object') {
          return option === item
        }
        return String(option.id) === String(item)
      })
      if (!option) {
        return ''
      }
      return getOptionDisplayValue(option)?.toString()
    })
    .filter(Boolean)

  if (!displayOptions.length) {
    return ''
  }

  if (props.fullLengthDisplayValue) {
    return displayOptions.join(', ')
  }

  return `${displayOptions[0]} ${
    displayOptions.length > 1
      ? t('and-more', {
          count: displayOptions.length - 1
        })
      : ''
  }`
}

const valueCard = computed(() => {
  if (!Array.isArray(props.value)) {
    return
  }

  return props.value
    .map((valueItem) => {
      const foundedOption = props.options.find((option) => {
        if (typeof valueItem === 'object') {
          return typeof option !== 'object'
            ? valueItem.id === option
            : String(valueItem.id) === String(option.id)
        }
        return typeof option !== 'object'
          ? valueItem === option
          : String(valueItem) === String(option.id)
      })
      if (!foundedOption) {
        return undefined
      }
      return getOptionDisplayValue(foundedOption)
    })
    .filter(Boolean)
})

const highlighted = computed(() => {
  return filterOptionsByTerm(
    props.options.filter((option) => {
      if (typeof option !== 'object') return false
      if ('highlighted' in option) return option.highlighted ?? false
      return false
    })
  )
})
const notHighlighted = computed(() => {
  return filterOptionsByTerm(
    props.options.filter((option) => {
      if (typeof option !== 'object') return true
      if ('highlighted' in option) return !option.highlighted
      return true
    })
  )
})
</script>
