<template>
  <AutobidIcon v-if="AUTOBID_ICONS[name.toLowerCase()]" />
  <Icon v-else mode="svg" :name="name" />
</template>

<script lang="ts" setup>
import { defineAsyncComponent } from 'vue'

interface Props {
  name: string
}

const props = defineProps<Props>()

const AUTOBID_ICONS = {
  padlock: 'Padlock',
  register: 'Register',
  search: 'Search',
  user: 'User',
  notification: 'Notification'
}

const AutobidIcon = defineAsyncComponent(
  () => import(`./icon/${AUTOBID_ICONS[props.name.toLowerCase()]}.vue`)
)
</script>
