<template>
  <Select
    :value="context.value"
    :placeholder="context.placeholder"
    :input-id="context.id"
    :options="options"
    :multiple="context.multiple"
    :disable-search="context.disableSearch"
    :required="context.state.required"
    @change="context.node.input"
  />
</template>

<script setup lang="ts">
import type { SelectOption } from '@autobid/ui/types/components/Select'
import { computed } from 'vue'
import { useCountries } from '@autobid/ui/composables/useCountries'
import Select from './select/Select.vue'

interface Props {
  context: {
    value: SelectOption[] | SelectOption
    label?: string
    multiple?: boolean
    disableSearch?: boolean
    placeholder?: string
    state: {
      required: boolean
    }
    node: {
      name?: string
      input: (value: boolean) => void
    }
    id: string
  }
}

defineProps<Props>()

const { data: countries } = useCountries()

const options = computed<SelectOption[]>(() => {
  if (!countries.value?.length) return []

  return [...countries.value]
    .map((el) => {
      return {
        id: `${el.id}`,
        label: el.name
      }
    })
    .sort((a, b) => {
      const aLabel = a.label.trim()
      const bLabel = b.label.trim()

      return aLabel.localeCompare(bLabel, 'en', { sensitivity: 'base' })
    })
})
</script>
