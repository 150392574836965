import type { RouteLocationNormalized } from 'vue-router'
import { getPageType } from '@autobid/ui/composables/car/useCarPageInfo'

export const pageTransitionMiddleware = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  currentPageType: 'barometer' | 'details'
) => {
  const prevPageType = from?.name ? getPageType(String(from.name)) : undefined
  const isPrevPageTheSameType =
    prevPageType &&
    (prevPageType === currentPageType ||
      (prevPageType === 'barometerCurrent' && currentPageType === 'barometer'))
  const isStillTheSameSubpage = to.path === from.path

  if (
    isStillTheSameSubpage ||
    !isPrevPageTheSameType ||
    !to.meta.pageTransition ||
    typeof to.meta.pageTransition === 'boolean'
  ) {
    to.meta.pageTransition = false
    return
  }

  const toggleOverflow = (type: 'add' | 'remove') => {
    const appWrapper = document.querySelector('.app-wrapper')
    appWrapper && appWrapper.classList[type]('overflow-x-hidden')
  }

  to.meta.pageTransition.name =
    to.query.pageType === 'prev' ? 'slide-right' : 'slide-left'

  to.meta.pageTransition.onBeforeLeave = () => toggleOverflow('add')
  to.meta.pageTransition.onAfterEnter = () => toggleOverflow('remove')
}
