<template>
  <div class="flex w-full justify-end gap-2">
    <button
      v-if="isOpen && filterCount"
      class="relative mb-2 flex items-center justify-center gap-4 space-x-1 bg-red p-1 px-4 text-white after:absolute after:inset-0 after:m-1 hover:text-white hover:after:bg-white/[0.12]"
      @click="reset"
    >
      {{ $t('clear') }}
    </button>
    <div class="relative mb-2">
      <ElementsButton
        rounded="primary"
        class="!px-2 transition hover:scale-105"
        @click="isOpen = !isOpen"
      >
        <span class="sr-only">{{ $t('auction-list.filters') }}</span>
        <Icon mode="svg" name="mi:filter" class="text-3xl"></Icon>
      </ElementsButton>
    </div>
    <span
      v-if="filterCount"
      class="absolute -right-1 -top-1 z-10 flex h-6 w-6 items-center justify-center rounded-full bg-white text-primary shadow-sm"
    >
      {{ filterCount }}
    </span>
  </div>
</template>

<script setup lang="ts">
import { useAuctionStore } from '@autobid/nuxt-pinia-store/store/useAuctionStore'
import { storeToRefs } from 'pinia'

const auctionStoreKey = inject('auctionStoreKey')
const { isOpen, filter } = storeToRefs(useAuctionStore(auctionStoreKey))

const { reset } = useAuctionStore(auctionStoreKey)

const filterCount = computed(() => {
  const { startDateRange, ...restFilterValues } = filter.value

  const baseCount = Object.values(restFilterValues).reduce((acc, current) => {
    return (acc += current.length)
  }, 0)

  const dateCount =
    startDateRange?.dateFrom?.length || startDateRange?.dateTo?.length ? 1 : 0

  return baseCount + dateCount
})
</script>
