<template>
  <div
    class="flex h-full flex-col justify-center border-b-4 bg-white @lg:hidden"
    :class="
      isMainPage
        ? 'border-black/90'
        : AUCTION_LIST_STAGE_BORDER_COLORS[auction.stage]
    "
  >
    <div ref="parent" class="flex w-full flex-col p-2">
      <button
        :aria-label="
          $t(expanded ? 'close' : 'open') +
          ' ' +
          $t('auction-list.auction') +
          ' ' +
          auction.title
        "
        class="flex w-full items-center justify-between border-b-2 border-slate-100"
        @click.stop="expanded = !expanded"
        @keyup.enter.stop="expanded = !expanded"
      >
        <Title :as-link="false" />
        <Icon
          mode="svg"
          name="mdi:chevron-down"
          size="42"
          class="transform transition duration-300 ease-in-out"
          :class="expanded ? 'rotate-180' : ''"
        />
      </button>

      <Descriptions
        v-if="expanded"
        class="p-2"
        :descriptions="auction.descriptions"
      />
    </div>

    <div class="flex w-full border-b-2 border-slate-100 p-2">
      <div class="flex w-full flex-col items-center justify-between gap-4">
        <div class="grid w-full grid-cols-2 justify-between gap-4">
          <StartDate />
          <Department />
        </div>
        <div class="flex h-full w-full">
          <div class="grid w-full grid-cols-2 gap-4">
            <AuctionListItemCategory
              v-if="auction.stage !== 'IN_PREPARATION'"
              class="h-full !items-start"
            />
            <div
              class="flex flex-col gap-4"
              :class="
                auction.stage === 'IN_PREPARATION'
                  ? 'col-span-2 justify-center'
                  : ''
              "
            >
              <div class="flex w-full">
                <img
                  v-if="auction.logos.length"
                  :key="auction.logos[0].url"
                  :src="auction.logos[0].url"
                  loading="lazy"
                  class="h-auto"
                  :class="auction.stage === 'IN_PREPARATION' ? 'mx-auto' : ''"
                  :alt="auction.logos[0].alt"
                />
              </div>
              <div
                :class="
                  auction.stage === 'IN_PREPARATION'
                    ? 'grid grid-cols-2 gap-4 p-4'
                    : ''
                "
              >
                <Designations
                  v-if="
                    auction.stage === 'IN_PREPARATION' &&
                    auction.designations.length
                  "
                  :designations="auction.designations"
                />
                <Catalog />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col justify-center">
        <CommonLink
          v-if="link"
          :href="shouldBlockTransition ? undefined : link"
        >
          <Icon
            mode="svg"
            name="mdi:chevron-right-circle"
            class="text-[#AFAFAF]"
            size="40"
          />
        </CommonLink>
      </div>
    </div>
    <div v-if="auction.notice" class="px-2 py-4">
      <Notice v-bind="auction.notice" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useAutoAnimate } from '@formkit/auto-animate/vue'
import type { DisplayingAuction } from '@autobid/ui/types/components/AuctionsData'
import { useAuctionLink } from '@autobid/ui/composables/useAuctionLink'
import { SHOULD_BLOCK_TRANSITION } from '@autobid/ui/constants/AuctionListItem/injectionsKeys'
import AuctionListItemCategory from './AuctionListItemCategory.vue'
import Descriptions from './AuctionListItemDescriptions.vue'
import Notice from './AuctionListItemNotice.vue'
import Title from './AuctionListItemTitle.vue'
import Catalog from './element/Catalog.vue'
import StartDate from './element/StartDate.vue'
import Department from './element/Department.vue'
import Designations from './element/Designations.vue'

const expanded = ref(false)

const [parent] = useAutoAnimate()

const auction: DisplayingAuction = inject('auction')

const AUCTION_LIST_STAGE_BORDER_COLORS = {
  ONLINE: 'border-[#B8CE21]',
  IN_PREPARATION: 'border-[#F59C00]',
  FINISHED: 'border-[#C1C1C1]'
}

const isMainPage = inject<boolean>('isMainPage')

const link = useAuctionLink({
  appId: auction.appId,
  slug: auction.slug,
  stage: auction.stage,
  startDate: auction.startDate
})

const shouldBlockTransition = inject(SHOULD_BLOCK_TRANSITION, ref(false))
</script>

<style scoped lang="scss">
* {
  user-select: none;
}
</style>
