<template>
  <CommonErrorBoundary>
    <template #default>
      <div class="w-full">
        <NuxtLayout>
          <div class="app-wrapper">
            <NuxtLoadingIndicator color="var(--color-primary)" />

            <NuxtPage />
          </div>
        </NuxtLayout>
      </div>

      <CommonDialogRequestError />
      <CommonStrapiPopup />
      <CommonDialogNotSupportedBrowser />
      <CommonDialogDisabledJavascript />
      <CommonMeilisearchPageBrowser />
      <CommonLoader />
      <SectionsSocketDebugger v-if="Number(INFO)" />

      <CommonNotivueInit />
    </template>

    <template #error="{ error }">
      <ErrorPage :error="error" />
      <CommonDialogDisabledJavascript />
    </template>
  </CommonErrorBoundary>
</template>

<script lang="ts" setup>
import { useAutobidAuth } from '@autobid/nuxt-auth/src/composables/useAutobidAuth'
import { ref, provide } from 'vue'
import { useToken } from '@autobid/nuxt-auth/src/composables/useToken'
import { SHOULD_USE_GLOBAL_BOUND_KEY } from '@autobid/ui/constants/SHOULD_USE_GLOBAL_BOUND_KEY'
import CommonErrorBoundary from '@autobid/ui/components/common/CustomErrorBoundary.vue'
import { useBody } from '@autobid/ui/composables/useBody'
import { changeLocale } from '@formkit/i18n'
import ErrorPage from './ErrorPage.vue'

const { $sentrySetUser } = useNuxtApp()
const { tokenData } = useToken()
const { isAuthed, afterAuthCallback } = useAutobidAuth()
const { locale } = useI18n()
const { getSession } = useAuth()
const runtimeConfig = useRuntimeConfig().public
const INFO = runtimeConfig.AUTOBID_WEBSOCKET?.INFO
const ONE_TRUST_ID = runtimeConfig.ONE_TRUST_ID

changeLocale(locale.value)

if (isAuthed.value && process.client) {
  await getSession()
}

await afterAuthCallback(locale.value)

const setSentryUser = () => {
  if (!$sentrySetUser || !tokenData.value) return

  const { id, nickname } = tokenData.value

  $sentrySetUser({ id, username: nickname })
}
setSentryUser()

const shouldUseGlobalErrorBoundary = ref(true)
provide(SHOULD_USE_GLOBAL_BOUND_KEY, shouldUseGlobalErrorBoundary)

useBody()

useHead({
  script:
    process.server && ONE_TRUST_ID?.length
      ? [
          {
            src: `https://cdn.cookielaw.org/consent/${ONE_TRUST_ID}/OtAutoBlock.js`
          },
          {
            src: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
            'data-domain-script': ONE_TRUST_ID
          },
          {
            innerHTML: 'function OptanonWrapper() {}',
            type: 'text/javascript'
          }
        ]
      : []
})
</script>
