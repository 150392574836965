import { onMounted, onBeforeUnmount, computed } from 'vue'

export const useIsMobile = () => {
  const cookie = useCookie('isMobile', { maxAge: 60 * 60 * 24 * 7 })
  const isMobile = computed(() => Boolean(cookie.value))

  const handleResize = () => {
    if (typeof window === 'undefined') {
      return
    }
    cookie.value = window.innerWidth < 768 ? 1 : 0
  }

  onMounted(() => {
    if (typeof window === 'undefined') {
      return
    }

    window.addEventListener('resize', handleResize)
  })

  onBeforeUnmount(() => {
    if (typeof window === 'undefined') {
      return
    }

    window.removeEventListener('resize', handleResize)
  })

  handleResize()

  return isMobile
}
