<template>
  <div class="w-full">
    <p v-if="label" class="mb-3">{{ label }}</p>

    <div class="col-span-12 grid w-full grid-cols-12 gap-3">
      <div
        v-for="option in options"
        :key="option.id"
        class="col-span-6 md:col-span-4 lg:col-span-3"
      >
        <Checkbox
          :checked="model.includes(option)"
          :label="option.label"
          small
          @change="handleChange(model.includes(option), option)"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import Checkbox from '@autobid/ui/components/elements/checkbox/Checkbox.vue'
import { computed } from 'vue'

type CheckboxValue = {
  id: string
  label: string
}

interface Props {
  value: CheckboxValue[]
  label?: string
  options?: CheckboxValue[]
}

interface Emits {
  (e: 'change', value: CheckboxValue[]): void
}

const emits = defineEmits<Emits>()

const props = defineProps<Props>()

const model = computed({
  get: () => props.value || [],
  set: (newValue: CheckboxValue[]) => emits('change', newValue)
})

const handleChange = (isAdded: boolean, value: CheckboxValue) => {
  if (isAdded) {
    model.value = model.value.filter((el) => el.id !== value.id)
  } else {
    model.value = [...model.value, value]
  }
}
</script>
