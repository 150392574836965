import type { AuctionCar } from '@autobid/ui/types/Car'
import type { Locale } from '@autobid/ui/types/I18n'

export type CarQueryProps = {
  lang: string
  locales: Locale[]
  currentData?: Partial<AuctionCar> | null
  ignoreCache?: boolean
  omitAuction?: boolean
}

type StaticFields =
  | 'state'
  | 'auctionId'
  | 'auctionNumber'
  | 'hotbidStartTime'
  | 'ranking'
  | 'manufacturer'
  | 'inActiveHotbidPhase'
  | 'hotbidDuration'
  | 'bidStep'
  | 'lowBudgetWarning'
  | 'bidOptions'
  | 'documentGroups'
  | 'publicationInfo'
  | 'dataAddition'
  | 'supplier'
  | 'ownBidAgentAmount'

type I18nStaticFields = 'goft'

// eslint-disable-next-line no-redeclare
export function getQueryField(field: StaticFields): string
// eslint-disable-next-line no-redeclare
export function getQueryField(field: I18nStaticFields, lang: string): string
// eslint-disable-next-line no-redeclare
export function getQueryField(
  field: StaticFields | I18nStaticFields,
  lang?: string
) {
  const fields: Record<typeof field, string> = {
    state: 'state',
    auctionId: 'auctionId',
    auctionNumber: 'auctionNumber',
    hotbidStartTime: 'hotbidStartTime',
    ranking: 'ranking',
    manufacturer: `manufacturer {
      name
    }`,
    inActiveHotbidPhase: 'inActiveHotbidPhase',
    hotbidDuration: `hotbidDuration {
      active
    }`,
    bidStep: 'bidStep',
    lowBudgetWarning: 'lowBudgetWarning',
    bidOptions: 'bidOptions',
    goft: `goft {
      ${lang?.toUpperCase()}{
        text
        headline
      }
    }`,
    documentGroups: `documentGroups {
      itemDocuments {
        generalPDF {
          en {
            url
            label
          }
          de {
            url
            label
          }
        }
      }
      itemAccidentPredamages {
        previousDamageExpertReport
      }
    }`,
    publicationInfo: `publicationInfo {
      status
      dataHash {
        item
      }
    }`,
    dataAddition: `dataAddition {
      id
      categoryLabel
      content
      additionTime
    }`,
    supplier: `supplier {
      id
      logo
      label
    }`,
    ownBidAgentAmount: 'ownBidAgentAmount'
  }

  return fields[field]
}
