import { default as _91_46_46_46slugs_93Tkh2DwzXzvMeta } from "/app/apps/premium/pages/[...slugs].vue?macro=true";
import { default as _91slug_93qKfaKMvUgmMeta } from "/app/apps/premium/pages/auction/[slug].vue?macro=true";
import { default as _91slug_939DdLR4V9XQMeta } from "/app/apps/premium/pages/auction/current/[slug].vue?macro=true";
import { default as exteriorEHmqmdIgL4Meta } from "/app/apps/premium/pages/beamer/[auctionId]/exterior.vue?macro=true";
import { default as indexgbpXXQ73roMeta } from "/app/apps/premium/pages/beamer/[auctionId]/index.vue?macro=true";
import { default as interiorfbUMgwFnZOMeta } from "/app/apps/premium/pages/beamer/[auctionId]/interior.vue?macro=true";
import { default as pdfX5b69KLndKMeta } from "/app/apps/premium/pages/cms/pdf.vue?macro=true";
import { default as preview_45popupJtWWWqggPJMeta } from "/app/apps/premium/pages/cms/preview-popup.vue?macro=true";
import { default as previewePwT9PCja7Meta } from "/app/apps/premium/pages/cms/preview.vue?macro=true";
import { default as slideY9kgKluY4KMeta } from "/app/apps/premium/pages/cms/slide.vue?macro=true";
import { default as detailsL6oxkYvYFuMeta } from "/app/apps/premium/pages/item/[slug]/details.vue?macro=true";
import { default as indexMvQlE1AIqkMeta } from "/app/apps/premium/pages/item/[slug]/index.vue?macro=true";
import { default as previewS7PgymDCArMeta } from "/app/apps/premium/pages/item/[slug]/preview.vue?macro=true";
import { default as loginVvBJLg8r8VMeta } from "/app/apps/premium/pages/login.vue?macro=true";
export default [
  {
    name: "slugs___en",
    path: "/en/:slugs(.*)*",
    meta: _91_46_46_46slugs_93Tkh2DwzXzvMeta || {},
    component: () => import("/app/apps/premium/pages/[...slugs].vue")
  },
  {
    name: "slugs___de___default",
    path: "/:slugs(.*)*",
    meta: _91_46_46_46slugs_93Tkh2DwzXzvMeta || {},
    component: () => import("/app/apps/premium/pages/[...slugs].vue")
  },
  {
    name: "slugs___de",
    path: "/de/:slugs(.*)*",
    meta: _91_46_46_46slugs_93Tkh2DwzXzvMeta || {},
    component: () => import("/app/apps/premium/pages/[...slugs].vue")
  },
  {
    name: "slugs___pl",
    path: "/pl/:slugs(.*)*",
    meta: _91_46_46_46slugs_93Tkh2DwzXzvMeta || {},
    component: () => import("/app/apps/premium/pages/[...slugs].vue")
  },
  {
    name: "slugs___cs",
    path: "/cs/:slugs(.*)*",
    meta: _91_46_46_46slugs_93Tkh2DwzXzvMeta || {},
    component: () => import("/app/apps/premium/pages/[...slugs].vue")
  },
  {
    name: "slugs___et",
    path: "/et/:slugs(.*)*",
    meta: _91_46_46_46slugs_93Tkh2DwzXzvMeta || {},
    component: () => import("/app/apps/premium/pages/[...slugs].vue")
  },
  {
    name: "slugs___es",
    path: "/es/:slugs(.*)*",
    meta: _91_46_46_46slugs_93Tkh2DwzXzvMeta || {},
    component: () => import("/app/apps/premium/pages/[...slugs].vue")
  },
  {
    name: "slugs___fr",
    path: "/fr/:slugs(.*)*",
    meta: _91_46_46_46slugs_93Tkh2DwzXzvMeta || {},
    component: () => import("/app/apps/premium/pages/[...slugs].vue")
  },
  {
    name: "slugs___hr",
    path: "/hr/:slugs(.*)*",
    meta: _91_46_46_46slugs_93Tkh2DwzXzvMeta || {},
    component: () => import("/app/apps/premium/pages/[...slugs].vue")
  },
  {
    name: "slugs___it",
    path: "/it/:slugs(.*)*",
    meta: _91_46_46_46slugs_93Tkh2DwzXzvMeta || {},
    component: () => import("/app/apps/premium/pages/[...slugs].vue")
  },
  {
    name: "slugs___lv",
    path: "/lv/:slugs(.*)*",
    meta: _91_46_46_46slugs_93Tkh2DwzXzvMeta || {},
    component: () => import("/app/apps/premium/pages/[...slugs].vue")
  },
  {
    name: "slugs___lt",
    path: "/lt/:slugs(.*)*",
    meta: _91_46_46_46slugs_93Tkh2DwzXzvMeta || {},
    component: () => import("/app/apps/premium/pages/[...slugs].vue")
  },
  {
    name: "slugs___hu",
    path: "/hu/:slugs(.*)*",
    meta: _91_46_46_46slugs_93Tkh2DwzXzvMeta || {},
    component: () => import("/app/apps/premium/pages/[...slugs].vue")
  },
  {
    name: "slugs___nl",
    path: "/nl/:slugs(.*)*",
    meta: _91_46_46_46slugs_93Tkh2DwzXzvMeta || {},
    component: () => import("/app/apps/premium/pages/[...slugs].vue")
  },
  {
    name: "slugs___pt",
    path: "/pt/:slugs(.*)*",
    meta: _91_46_46_46slugs_93Tkh2DwzXzvMeta || {},
    component: () => import("/app/apps/premium/pages/[...slugs].vue")
  },
  {
    name: "slugs___ro",
    path: "/ro/:slugs(.*)*",
    meta: _91_46_46_46slugs_93Tkh2DwzXzvMeta || {},
    component: () => import("/app/apps/premium/pages/[...slugs].vue")
  },
  {
    name: "slugs___sk",
    path: "/sk/:slugs(.*)*",
    meta: _91_46_46_46slugs_93Tkh2DwzXzvMeta || {},
    component: () => import("/app/apps/premium/pages/[...slugs].vue")
  },
  {
    name: "slugs___sl",
    path: "/sl/:slugs(.*)*",
    meta: _91_46_46_46slugs_93Tkh2DwzXzvMeta || {},
    component: () => import("/app/apps/premium/pages/[...slugs].vue")
  },
  {
    name: "slugs___sr",
    path: "/sr/:slugs(.*)*",
    meta: _91_46_46_46slugs_93Tkh2DwzXzvMeta || {},
    component: () => import("/app/apps/premium/pages/[...slugs].vue")
  },
  {
    name: "slugs___tr",
    path: "/tr/:slugs(.*)*",
    meta: _91_46_46_46slugs_93Tkh2DwzXzvMeta || {},
    component: () => import("/app/apps/premium/pages/[...slugs].vue")
  },
  {
    name: "slugs___el",
    path: "/el/:slugs(.*)*",
    meta: _91_46_46_46slugs_93Tkh2DwzXzvMeta || {},
    component: () => import("/app/apps/premium/pages/[...slugs].vue")
  },
  {
    name: "slugs___bg",
    path: "/bg/:slugs(.*)*",
    meta: _91_46_46_46slugs_93Tkh2DwzXzvMeta || {},
    component: () => import("/app/apps/premium/pages/[...slugs].vue")
  },
  {
    name: "slugs___ru",
    path: "/ru/:slugs(.*)*",
    meta: _91_46_46_46slugs_93Tkh2DwzXzvMeta || {},
    component: () => import("/app/apps/premium/pages/[...slugs].vue")
  },
  {
    name: "auction-slug___en",
    path: "/en/auction/:slug()",
    meta: _91slug_93qKfaKMvUgmMeta || {},
    component: () => import("/app/apps/premium/pages/auction/[slug].vue")
  },
  {
    name: "auction-slug___de___default",
    path: "/auktion/:slug()",
    meta: _91slug_93qKfaKMvUgmMeta || {},
    component: () => import("/app/apps/premium/pages/auction/[slug].vue")
  },
  {
    name: "auction-slug___de",
    path: "/de/auktion/:slug()",
    meta: _91slug_93qKfaKMvUgmMeta || {},
    component: () => import("/app/apps/premium/pages/auction/[slug].vue")
  },
  {
    name: "auction-slug___pl",
    path: "/pl/aukcja/:slug()",
    meta: _91slug_93qKfaKMvUgmMeta || {},
    component: () => import("/app/apps/premium/pages/auction/[slug].vue")
  },
  {
    name: "auction-slug___cs",
    path: "/cs/aukce/:slug()",
    meta: _91slug_93qKfaKMvUgmMeta || {},
    component: () => import("/app/apps/premium/pages/auction/[slug].vue")
  },
  {
    name: "auction-slug___et",
    path: "/et/oksjon/:slug()",
    meta: _91slug_93qKfaKMvUgmMeta || {},
    component: () => import("/app/apps/premium/pages/auction/[slug].vue")
  },
  {
    name: "auction-slug___es",
    path: "/es/subasta/:slug()",
    meta: _91slug_93qKfaKMvUgmMeta || {},
    component: () => import("/app/apps/premium/pages/auction/[slug].vue")
  },
  {
    name: "auction-slug___fr",
    path: "/fr/encheres/:slug()",
    meta: _91slug_93qKfaKMvUgmMeta || {},
    component: () => import("/app/apps/premium/pages/auction/[slug].vue")
  },
  {
    name: "auction-slug___hr",
    path: "/hr/aukcija/:slug()",
    meta: _91slug_93qKfaKMvUgmMeta || {},
    component: () => import("/app/apps/premium/pages/auction/[slug].vue")
  },
  {
    name: "auction-slug___it",
    path: "/it/asta/:slug()",
    meta: _91slug_93qKfaKMvUgmMeta || {},
    component: () => import("/app/apps/premium/pages/auction/[slug].vue")
  },
  {
    name: "auction-slug___lv",
    path: "/lv/izsole/:slug()",
    meta: _91slug_93qKfaKMvUgmMeta || {},
    component: () => import("/app/apps/premium/pages/auction/[slug].vue")
  },
  {
    name: "auction-slug___lt",
    path: "/lt/aukcione/:slug()",
    meta: _91slug_93qKfaKMvUgmMeta || {},
    component: () => import("/app/apps/premium/pages/auction/[slug].vue")
  },
  {
    name: "auction-slug___hu",
    path: "/hu/arveres/:slug()",
    meta: _91slug_93qKfaKMvUgmMeta || {},
    component: () => import("/app/apps/premium/pages/auction/[slug].vue")
  },
  {
    name: "auction-slug___nl",
    path: "/nl/veiling/:slug()",
    meta: _91slug_93qKfaKMvUgmMeta || {},
    component: () => import("/app/apps/premium/pages/auction/[slug].vue")
  },
  {
    name: "auction-slug___pt",
    path: "/pt/leilao/:slug()",
    meta: _91slug_93qKfaKMvUgmMeta || {},
    component: () => import("/app/apps/premium/pages/auction/[slug].vue")
  },
  {
    name: "auction-slug___ro",
    path: "/ro/licitatie/:slug()",
    meta: _91slug_93qKfaKMvUgmMeta || {},
    component: () => import("/app/apps/premium/pages/auction/[slug].vue")
  },
  {
    name: "auction-slug___sk",
    path: "/sk/aukcie/:slug()",
    meta: _91slug_93qKfaKMvUgmMeta || {},
    component: () => import("/app/apps/premium/pages/auction/[slug].vue")
  },
  {
    name: "auction-slug___sl",
    path: "/sl/drazba/:slug()",
    meta: _91slug_93qKfaKMvUgmMeta || {},
    component: () => import("/app/apps/premium/pages/auction/[slug].vue")
  },
  {
    name: "auction-slug___sr",
    path: "/sr/aukcija/:slug()",
    meta: _91slug_93qKfaKMvUgmMeta || {},
    component: () => import("/app/apps/premium/pages/auction/[slug].vue")
  },
  {
    name: "auction-slug___tr",
    path: "/tr/acik-arttirma/:slug()",
    meta: _91slug_93qKfaKMvUgmMeta || {},
    component: () => import("/app/apps/premium/pages/auction/[slug].vue")
  },
  {
    name: "auction-slug___el",
    path: "/el/dhmoprasia/:slug()",
    meta: _91slug_93qKfaKMvUgmMeta || {},
    component: () => import("/app/apps/premium/pages/auction/[slug].vue")
  },
  {
    name: "auction-slug___bg",
    path: "/bg/turg/:slug()",
    meta: _91slug_93qKfaKMvUgmMeta || {},
    component: () => import("/app/apps/premium/pages/auction/[slug].vue")
  },
  {
    name: "auction-slug___ru",
    path: "/ru/aukcion/:slug()",
    meta: _91slug_93qKfaKMvUgmMeta || {},
    component: () => import("/app/apps/premium/pages/auction/[slug].vue")
  },
  {
    name: "auction-current-slug___en",
    path: "/en/auction/current/:slug()",
    meta: _91slug_939DdLR4V9XQMeta || {},
    component: () => import("/app/apps/premium/pages/auction/current/[slug].vue")
  },
  {
    name: "auction-current-slug___de___default",
    path: "/auktion/aktuell/:slug()",
    meta: _91slug_939DdLR4V9XQMeta || {},
    component: () => import("/app/apps/premium/pages/auction/current/[slug].vue")
  },
  {
    name: "auction-current-slug___de",
    path: "/de/auktion/aktuell/:slug()",
    meta: _91slug_939DdLR4V9XQMeta || {},
    component: () => import("/app/apps/premium/pages/auction/current/[slug].vue")
  },
  {
    name: "auction-current-slug___pl",
    path: "/pl/aukcja/aktualna/:slug()",
    meta: _91slug_939DdLR4V9XQMeta || {},
    component: () => import("/app/apps/premium/pages/auction/current/[slug].vue")
  },
  {
    name: "auction-current-slug___cs",
    path: "/cs/aukce/aktualni/:slug()",
    meta: _91slug_939DdLR4V9XQMeta || {},
    component: () => import("/app/apps/premium/pages/auction/current/[slug].vue")
  },
  {
    name: "auction-current-slug___et",
    path: "/et/oksjon/praegune/:slug()",
    meta: _91slug_939DdLR4V9XQMeta || {},
    component: () => import("/app/apps/premium/pages/auction/current/[slug].vue")
  },
  {
    name: "auction-current-slug___es",
    path: "/es/subasta/actual/:slug()",
    meta: _91slug_939DdLR4V9XQMeta || {},
    component: () => import("/app/apps/premium/pages/auction/current/[slug].vue")
  },
  {
    name: "auction-current-slug___fr",
    path: "/fr/encheres/actuel/:slug()",
    meta: _91slug_939DdLR4V9XQMeta || {},
    component: () => import("/app/apps/premium/pages/auction/current/[slug].vue")
  },
  {
    name: "auction-current-slug___hr",
    path: "/hr/aukcija/trenutno/:slug()",
    meta: _91slug_939DdLR4V9XQMeta || {},
    component: () => import("/app/apps/premium/pages/auction/current/[slug].vue")
  },
  {
    name: "auction-current-slug___it",
    path: "/it/asta/attuale/:slug()",
    meta: _91slug_939DdLR4V9XQMeta || {},
    component: () => import("/app/apps/premium/pages/auction/current/[slug].vue")
  },
  {
    name: "auction-current-slug___lv",
    path: "/lv/izsole/strava/:slug()",
    meta: _91slug_939DdLR4V9XQMeta || {},
    component: () => import("/app/apps/premium/pages/auction/current/[slug].vue")
  },
  {
    name: "auction-current-slug___lt",
    path: "/lt/aukcione/srove/:slug()",
    meta: _91slug_939DdLR4V9XQMeta || {},
    component: () => import("/app/apps/premium/pages/auction/current/[slug].vue")
  },
  {
    name: "auction-current-slug___hu",
    path: "/hu/arveres/jelenlegi/:slug()",
    meta: _91slug_939DdLR4V9XQMeta || {},
    component: () => import("/app/apps/premium/pages/auction/current/[slug].vue")
  },
  {
    name: "auction-current-slug___nl",
    path: "/nl/veiling/huidig/:slug()",
    meta: _91slug_939DdLR4V9XQMeta || {},
    component: () => import("/app/apps/premium/pages/auction/current/[slug].vue")
  },
  {
    name: "auction-current-slug___pt",
    path: "/pt/leilao/atual/:slug()",
    meta: _91slug_939DdLR4V9XQMeta || {},
    component: () => import("/app/apps/premium/pages/auction/current/[slug].vue")
  },
  {
    name: "auction-current-slug___ro",
    path: "/ro/licitatie/actual/:slug()",
    meta: _91slug_939DdLR4V9XQMeta || {},
    component: () => import("/app/apps/premium/pages/auction/current/[slug].vue")
  },
  {
    name: "auction-current-slug___sk",
    path: "/sk/aukcie/prud/:slug()",
    meta: _91slug_939DdLR4V9XQMeta || {},
    component: () => import("/app/apps/premium/pages/auction/current/[slug].vue")
  },
  {
    name: "auction-current-slug___sl",
    path: "/sl/drazba/trenutno/:slug()",
    meta: _91slug_939DdLR4V9XQMeta || {},
    component: () => import("/app/apps/premium/pages/auction/current/[slug].vue")
  },
  {
    name: "auction-current-slug___sr",
    path: "/sr/aukcija/trenutni/:slug()",
    meta: _91slug_939DdLR4V9XQMeta || {},
    component: () => import("/app/apps/premium/pages/auction/current/[slug].vue")
  },
  {
    name: "auction-current-slug___tr",
    path: "/tr/acik-arttirma/akim/:slug()",
    meta: _91slug_939DdLR4V9XQMeta || {},
    component: () => import("/app/apps/premium/pages/auction/current/[slug].vue")
  },
  {
    name: "auction-current-slug___el",
    path: "/el/dhmoprasia/reyma/:slug()",
    meta: _91slug_939DdLR4V9XQMeta || {},
    component: () => import("/app/apps/premium/pages/auction/current/[slug].vue")
  },
  {
    name: "auction-current-slug___bg",
    path: "/bg/turg/tekush/:slug()",
    meta: _91slug_939DdLR4V9XQMeta || {},
    component: () => import("/app/apps/premium/pages/auction/current/[slug].vue")
  },
  {
    name: "auction-current-slug___ru",
    path: "/ru/aukcion/tekushij/:slug()",
    meta: _91slug_939DdLR4V9XQMeta || {},
    component: () => import("/app/apps/premium/pages/auction/current/[slug].vue")
  },
  {
    name: "beamer-auctionId-exterior___en",
    path: "/en/beamer/:auctionId()/exterior",
    meta: exteriorEHmqmdIgL4Meta || {},
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/exterior.vue")
  },
  {
    name: "beamer-auctionId-exterior___de___default",
    path: "/beamer/:auctionId()/exterior",
    meta: exteriorEHmqmdIgL4Meta || {},
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/exterior.vue")
  },
  {
    name: "beamer-auctionId-exterior___de",
    path: "/de/beamer/:auctionId()/exterior",
    meta: exteriorEHmqmdIgL4Meta || {},
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/exterior.vue")
  },
  {
    name: "beamer-auctionId-exterior___pl",
    path: "/pl/beamer/:auctionId()/exterior",
    meta: exteriorEHmqmdIgL4Meta || {},
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/exterior.vue")
  },
  {
    name: "beamer-auctionId-exterior___cs",
    path: "/cs/beamer/:auctionId()/exterior",
    meta: exteriorEHmqmdIgL4Meta || {},
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/exterior.vue")
  },
  {
    name: "beamer-auctionId-exterior___et",
    path: "/et/beamer/:auctionId()/exterior",
    meta: exteriorEHmqmdIgL4Meta || {},
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/exterior.vue")
  },
  {
    name: "beamer-auctionId-exterior___es",
    path: "/es/beamer/:auctionId()/exterior",
    meta: exteriorEHmqmdIgL4Meta || {},
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/exterior.vue")
  },
  {
    name: "beamer-auctionId-exterior___fr",
    path: "/fr/beamer/:auctionId()/exterior",
    meta: exteriorEHmqmdIgL4Meta || {},
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/exterior.vue")
  },
  {
    name: "beamer-auctionId-exterior___hr",
    path: "/hr/beamer/:auctionId()/exterior",
    meta: exteriorEHmqmdIgL4Meta || {},
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/exterior.vue")
  },
  {
    name: "beamer-auctionId-exterior___it",
    path: "/it/beamer/:auctionId()/exterior",
    meta: exteriorEHmqmdIgL4Meta || {},
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/exterior.vue")
  },
  {
    name: "beamer-auctionId-exterior___lv",
    path: "/lv/beamer/:auctionId()/exterior",
    meta: exteriorEHmqmdIgL4Meta || {},
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/exterior.vue")
  },
  {
    name: "beamer-auctionId-exterior___lt",
    path: "/lt/beamer/:auctionId()/exterior",
    meta: exteriorEHmqmdIgL4Meta || {},
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/exterior.vue")
  },
  {
    name: "beamer-auctionId-exterior___hu",
    path: "/hu/beamer/:auctionId()/exterior",
    meta: exteriorEHmqmdIgL4Meta || {},
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/exterior.vue")
  },
  {
    name: "beamer-auctionId-exterior___nl",
    path: "/nl/beamer/:auctionId()/exterior",
    meta: exteriorEHmqmdIgL4Meta || {},
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/exterior.vue")
  },
  {
    name: "beamer-auctionId-exterior___pt",
    path: "/pt/beamer/:auctionId()/exterior",
    meta: exteriorEHmqmdIgL4Meta || {},
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/exterior.vue")
  },
  {
    name: "beamer-auctionId-exterior___ro",
    path: "/ro/beamer/:auctionId()/exterior",
    meta: exteriorEHmqmdIgL4Meta || {},
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/exterior.vue")
  },
  {
    name: "beamer-auctionId-exterior___sk",
    path: "/sk/beamer/:auctionId()/exterior",
    meta: exteriorEHmqmdIgL4Meta || {},
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/exterior.vue")
  },
  {
    name: "beamer-auctionId-exterior___sl",
    path: "/sl/beamer/:auctionId()/exterior",
    meta: exteriorEHmqmdIgL4Meta || {},
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/exterior.vue")
  },
  {
    name: "beamer-auctionId-exterior___sr",
    path: "/sr/beamer/:auctionId()/exterior",
    meta: exteriorEHmqmdIgL4Meta || {},
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/exterior.vue")
  },
  {
    name: "beamer-auctionId-exterior___tr",
    path: "/tr/beamer/:auctionId()/exterior",
    meta: exteriorEHmqmdIgL4Meta || {},
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/exterior.vue")
  },
  {
    name: "beamer-auctionId-exterior___el",
    path: "/el/beamer/:auctionId()/exterior",
    meta: exteriorEHmqmdIgL4Meta || {},
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/exterior.vue")
  },
  {
    name: "beamer-auctionId-exterior___bg",
    path: "/bg/beamer/:auctionId()/exterior",
    meta: exteriorEHmqmdIgL4Meta || {},
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/exterior.vue")
  },
  {
    name: "beamer-auctionId-exterior___ru",
    path: "/ru/beamer/:auctionId()/exterior",
    meta: exteriorEHmqmdIgL4Meta || {},
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/exterior.vue")
  },
  {
    name: "beamer-auctionId___en",
    path: "/en/beamer/:auctionId()",
    meta: indexgbpXXQ73roMeta || {},
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/index.vue")
  },
  {
    name: "beamer-auctionId___de___default",
    path: "/beamer/:auctionId()",
    meta: indexgbpXXQ73roMeta || {},
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/index.vue")
  },
  {
    name: "beamer-auctionId___de",
    path: "/de/beamer/:auctionId()",
    meta: indexgbpXXQ73roMeta || {},
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/index.vue")
  },
  {
    name: "beamer-auctionId___pl",
    path: "/pl/beamer/:auctionId()",
    meta: indexgbpXXQ73roMeta || {},
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/index.vue")
  },
  {
    name: "beamer-auctionId___cs",
    path: "/cs/beamer/:auctionId()",
    meta: indexgbpXXQ73roMeta || {},
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/index.vue")
  },
  {
    name: "beamer-auctionId___et",
    path: "/et/beamer/:auctionId()",
    meta: indexgbpXXQ73roMeta || {},
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/index.vue")
  },
  {
    name: "beamer-auctionId___es",
    path: "/es/beamer/:auctionId()",
    meta: indexgbpXXQ73roMeta || {},
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/index.vue")
  },
  {
    name: "beamer-auctionId___fr",
    path: "/fr/beamer/:auctionId()",
    meta: indexgbpXXQ73roMeta || {},
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/index.vue")
  },
  {
    name: "beamer-auctionId___hr",
    path: "/hr/beamer/:auctionId()",
    meta: indexgbpXXQ73roMeta || {},
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/index.vue")
  },
  {
    name: "beamer-auctionId___it",
    path: "/it/beamer/:auctionId()",
    meta: indexgbpXXQ73roMeta || {},
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/index.vue")
  },
  {
    name: "beamer-auctionId___lv",
    path: "/lv/beamer/:auctionId()",
    meta: indexgbpXXQ73roMeta || {},
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/index.vue")
  },
  {
    name: "beamer-auctionId___lt",
    path: "/lt/beamer/:auctionId()",
    meta: indexgbpXXQ73roMeta || {},
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/index.vue")
  },
  {
    name: "beamer-auctionId___hu",
    path: "/hu/beamer/:auctionId()",
    meta: indexgbpXXQ73roMeta || {},
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/index.vue")
  },
  {
    name: "beamer-auctionId___nl",
    path: "/nl/beamer/:auctionId()",
    meta: indexgbpXXQ73roMeta || {},
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/index.vue")
  },
  {
    name: "beamer-auctionId___pt",
    path: "/pt/beamer/:auctionId()",
    meta: indexgbpXXQ73roMeta || {},
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/index.vue")
  },
  {
    name: "beamer-auctionId___ro",
    path: "/ro/beamer/:auctionId()",
    meta: indexgbpXXQ73roMeta || {},
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/index.vue")
  },
  {
    name: "beamer-auctionId___sk",
    path: "/sk/beamer/:auctionId()",
    meta: indexgbpXXQ73roMeta || {},
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/index.vue")
  },
  {
    name: "beamer-auctionId___sl",
    path: "/sl/beamer/:auctionId()",
    meta: indexgbpXXQ73roMeta || {},
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/index.vue")
  },
  {
    name: "beamer-auctionId___sr",
    path: "/sr/beamer/:auctionId()",
    meta: indexgbpXXQ73roMeta || {},
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/index.vue")
  },
  {
    name: "beamer-auctionId___tr",
    path: "/tr/beamer/:auctionId()",
    meta: indexgbpXXQ73roMeta || {},
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/index.vue")
  },
  {
    name: "beamer-auctionId___el",
    path: "/el/beamer/:auctionId()",
    meta: indexgbpXXQ73roMeta || {},
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/index.vue")
  },
  {
    name: "beamer-auctionId___bg",
    path: "/bg/beamer/:auctionId()",
    meta: indexgbpXXQ73roMeta || {},
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/index.vue")
  },
  {
    name: "beamer-auctionId___ru",
    path: "/ru/beamer/:auctionId()",
    meta: indexgbpXXQ73roMeta || {},
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/index.vue")
  },
  {
    name: "beamer-auctionId-interior___en",
    path: "/en/beamer/:auctionId()/interior",
    meta: interiorfbUMgwFnZOMeta || {},
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/interior.vue")
  },
  {
    name: "beamer-auctionId-interior___de___default",
    path: "/beamer/:auctionId()/interior",
    meta: interiorfbUMgwFnZOMeta || {},
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/interior.vue")
  },
  {
    name: "beamer-auctionId-interior___de",
    path: "/de/beamer/:auctionId()/interior",
    meta: interiorfbUMgwFnZOMeta || {},
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/interior.vue")
  },
  {
    name: "beamer-auctionId-interior___pl",
    path: "/pl/beamer/:auctionId()/interior",
    meta: interiorfbUMgwFnZOMeta || {},
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/interior.vue")
  },
  {
    name: "beamer-auctionId-interior___cs",
    path: "/cs/beamer/:auctionId()/interior",
    meta: interiorfbUMgwFnZOMeta || {},
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/interior.vue")
  },
  {
    name: "beamer-auctionId-interior___et",
    path: "/et/beamer/:auctionId()/interior",
    meta: interiorfbUMgwFnZOMeta || {},
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/interior.vue")
  },
  {
    name: "beamer-auctionId-interior___es",
    path: "/es/beamer/:auctionId()/interior",
    meta: interiorfbUMgwFnZOMeta || {},
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/interior.vue")
  },
  {
    name: "beamer-auctionId-interior___fr",
    path: "/fr/beamer/:auctionId()/interior",
    meta: interiorfbUMgwFnZOMeta || {},
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/interior.vue")
  },
  {
    name: "beamer-auctionId-interior___hr",
    path: "/hr/beamer/:auctionId()/interior",
    meta: interiorfbUMgwFnZOMeta || {},
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/interior.vue")
  },
  {
    name: "beamer-auctionId-interior___it",
    path: "/it/beamer/:auctionId()/interior",
    meta: interiorfbUMgwFnZOMeta || {},
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/interior.vue")
  },
  {
    name: "beamer-auctionId-interior___lv",
    path: "/lv/beamer/:auctionId()/interior",
    meta: interiorfbUMgwFnZOMeta || {},
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/interior.vue")
  },
  {
    name: "beamer-auctionId-interior___lt",
    path: "/lt/beamer/:auctionId()/interior",
    meta: interiorfbUMgwFnZOMeta || {},
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/interior.vue")
  },
  {
    name: "beamer-auctionId-interior___hu",
    path: "/hu/beamer/:auctionId()/interior",
    meta: interiorfbUMgwFnZOMeta || {},
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/interior.vue")
  },
  {
    name: "beamer-auctionId-interior___nl",
    path: "/nl/beamer/:auctionId()/interior",
    meta: interiorfbUMgwFnZOMeta || {},
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/interior.vue")
  },
  {
    name: "beamer-auctionId-interior___pt",
    path: "/pt/beamer/:auctionId()/interior",
    meta: interiorfbUMgwFnZOMeta || {},
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/interior.vue")
  },
  {
    name: "beamer-auctionId-interior___ro",
    path: "/ro/beamer/:auctionId()/interior",
    meta: interiorfbUMgwFnZOMeta || {},
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/interior.vue")
  },
  {
    name: "beamer-auctionId-interior___sk",
    path: "/sk/beamer/:auctionId()/interior",
    meta: interiorfbUMgwFnZOMeta || {},
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/interior.vue")
  },
  {
    name: "beamer-auctionId-interior___sl",
    path: "/sl/beamer/:auctionId()/interior",
    meta: interiorfbUMgwFnZOMeta || {},
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/interior.vue")
  },
  {
    name: "beamer-auctionId-interior___sr",
    path: "/sr/beamer/:auctionId()/interior",
    meta: interiorfbUMgwFnZOMeta || {},
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/interior.vue")
  },
  {
    name: "beamer-auctionId-interior___tr",
    path: "/tr/beamer/:auctionId()/interior",
    meta: interiorfbUMgwFnZOMeta || {},
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/interior.vue")
  },
  {
    name: "beamer-auctionId-interior___el",
    path: "/el/beamer/:auctionId()/interior",
    meta: interiorfbUMgwFnZOMeta || {},
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/interior.vue")
  },
  {
    name: "beamer-auctionId-interior___bg",
    path: "/bg/beamer/:auctionId()/interior",
    meta: interiorfbUMgwFnZOMeta || {},
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/interior.vue")
  },
  {
    name: "beamer-auctionId-interior___ru",
    path: "/ru/beamer/:auctionId()/interior",
    meta: interiorfbUMgwFnZOMeta || {},
    component: () => import("/app/apps/premium/pages/beamer/[auctionId]/interior.vue")
  },
  {
    name: "cms-pdf___en",
    path: "/en/cms/pdf",
    component: () => import("/app/apps/premium/pages/cms/pdf.vue")
  },
  {
    name: "cms-pdf___de___default",
    path: "/cms/pdf",
    component: () => import("/app/apps/premium/pages/cms/pdf.vue")
  },
  {
    name: "cms-pdf___de",
    path: "/de/cms/pdf",
    component: () => import("/app/apps/premium/pages/cms/pdf.vue")
  },
  {
    name: "cms-pdf___pl",
    path: "/pl/cms/pdf",
    component: () => import("/app/apps/premium/pages/cms/pdf.vue")
  },
  {
    name: "cms-pdf___cs",
    path: "/cs/cms/pdf",
    component: () => import("/app/apps/premium/pages/cms/pdf.vue")
  },
  {
    name: "cms-pdf___et",
    path: "/et/cms/pdf",
    component: () => import("/app/apps/premium/pages/cms/pdf.vue")
  },
  {
    name: "cms-pdf___es",
    path: "/es/cms/pdf",
    component: () => import("/app/apps/premium/pages/cms/pdf.vue")
  },
  {
    name: "cms-pdf___fr",
    path: "/fr/cms/pdf",
    component: () => import("/app/apps/premium/pages/cms/pdf.vue")
  },
  {
    name: "cms-pdf___hr",
    path: "/hr/cms/pdf",
    component: () => import("/app/apps/premium/pages/cms/pdf.vue")
  },
  {
    name: "cms-pdf___it",
    path: "/it/cms/pdf",
    component: () => import("/app/apps/premium/pages/cms/pdf.vue")
  },
  {
    name: "cms-pdf___lv",
    path: "/lv/cms/pdf",
    component: () => import("/app/apps/premium/pages/cms/pdf.vue")
  },
  {
    name: "cms-pdf___lt",
    path: "/lt/cms/pdf",
    component: () => import("/app/apps/premium/pages/cms/pdf.vue")
  },
  {
    name: "cms-pdf___hu",
    path: "/hu/cms/pdf",
    component: () => import("/app/apps/premium/pages/cms/pdf.vue")
  },
  {
    name: "cms-pdf___nl",
    path: "/nl/cms/pdf",
    component: () => import("/app/apps/premium/pages/cms/pdf.vue")
  },
  {
    name: "cms-pdf___pt",
    path: "/pt/cms/pdf",
    component: () => import("/app/apps/premium/pages/cms/pdf.vue")
  },
  {
    name: "cms-pdf___ro",
    path: "/ro/cms/pdf",
    component: () => import("/app/apps/premium/pages/cms/pdf.vue")
  },
  {
    name: "cms-pdf___sk",
    path: "/sk/cms/pdf",
    component: () => import("/app/apps/premium/pages/cms/pdf.vue")
  },
  {
    name: "cms-pdf___sl",
    path: "/sl/cms/pdf",
    component: () => import("/app/apps/premium/pages/cms/pdf.vue")
  },
  {
    name: "cms-pdf___sr",
    path: "/sr/cms/pdf",
    component: () => import("/app/apps/premium/pages/cms/pdf.vue")
  },
  {
    name: "cms-pdf___tr",
    path: "/tr/cms/pdf",
    component: () => import("/app/apps/premium/pages/cms/pdf.vue")
  },
  {
    name: "cms-pdf___el",
    path: "/el/cms/pdf",
    component: () => import("/app/apps/premium/pages/cms/pdf.vue")
  },
  {
    name: "cms-pdf___bg",
    path: "/bg/cms/pdf",
    component: () => import("/app/apps/premium/pages/cms/pdf.vue")
  },
  {
    name: "cms-pdf___ru",
    path: "/ru/cms/pdf",
    component: () => import("/app/apps/premium/pages/cms/pdf.vue")
  },
  {
    name: "cms-preview-popup___en",
    path: "/en/cms/preview-popup",
    meta: preview_45popupJtWWWqggPJMeta || {},
    component: () => import("/app/apps/premium/pages/cms/preview-popup.vue")
  },
  {
    name: "cms-preview-popup___de___default",
    path: "/cms/preview-popup",
    meta: preview_45popupJtWWWqggPJMeta || {},
    component: () => import("/app/apps/premium/pages/cms/preview-popup.vue")
  },
  {
    name: "cms-preview-popup___de",
    path: "/de/cms/preview-popup",
    meta: preview_45popupJtWWWqggPJMeta || {},
    component: () => import("/app/apps/premium/pages/cms/preview-popup.vue")
  },
  {
    name: "cms-preview-popup___pl",
    path: "/pl/cms/preview-popup",
    meta: preview_45popupJtWWWqggPJMeta || {},
    component: () => import("/app/apps/premium/pages/cms/preview-popup.vue")
  },
  {
    name: "cms-preview-popup___cs",
    path: "/cs/cms/preview-popup",
    meta: preview_45popupJtWWWqggPJMeta || {},
    component: () => import("/app/apps/premium/pages/cms/preview-popup.vue")
  },
  {
    name: "cms-preview-popup___et",
    path: "/et/cms/preview-popup",
    meta: preview_45popupJtWWWqggPJMeta || {},
    component: () => import("/app/apps/premium/pages/cms/preview-popup.vue")
  },
  {
    name: "cms-preview-popup___es",
    path: "/es/cms/preview-popup",
    meta: preview_45popupJtWWWqggPJMeta || {},
    component: () => import("/app/apps/premium/pages/cms/preview-popup.vue")
  },
  {
    name: "cms-preview-popup___fr",
    path: "/fr/cms/preview-popup",
    meta: preview_45popupJtWWWqggPJMeta || {},
    component: () => import("/app/apps/premium/pages/cms/preview-popup.vue")
  },
  {
    name: "cms-preview-popup___hr",
    path: "/hr/cms/preview-popup",
    meta: preview_45popupJtWWWqggPJMeta || {},
    component: () => import("/app/apps/premium/pages/cms/preview-popup.vue")
  },
  {
    name: "cms-preview-popup___it",
    path: "/it/cms/preview-popup",
    meta: preview_45popupJtWWWqggPJMeta || {},
    component: () => import("/app/apps/premium/pages/cms/preview-popup.vue")
  },
  {
    name: "cms-preview-popup___lv",
    path: "/lv/cms/preview-popup",
    meta: preview_45popupJtWWWqggPJMeta || {},
    component: () => import("/app/apps/premium/pages/cms/preview-popup.vue")
  },
  {
    name: "cms-preview-popup___lt",
    path: "/lt/cms/preview-popup",
    meta: preview_45popupJtWWWqggPJMeta || {},
    component: () => import("/app/apps/premium/pages/cms/preview-popup.vue")
  },
  {
    name: "cms-preview-popup___hu",
    path: "/hu/cms/preview-popup",
    meta: preview_45popupJtWWWqggPJMeta || {},
    component: () => import("/app/apps/premium/pages/cms/preview-popup.vue")
  },
  {
    name: "cms-preview-popup___nl",
    path: "/nl/cms/preview-popup",
    meta: preview_45popupJtWWWqggPJMeta || {},
    component: () => import("/app/apps/premium/pages/cms/preview-popup.vue")
  },
  {
    name: "cms-preview-popup___pt",
    path: "/pt/cms/preview-popup",
    meta: preview_45popupJtWWWqggPJMeta || {},
    component: () => import("/app/apps/premium/pages/cms/preview-popup.vue")
  },
  {
    name: "cms-preview-popup___ro",
    path: "/ro/cms/preview-popup",
    meta: preview_45popupJtWWWqggPJMeta || {},
    component: () => import("/app/apps/premium/pages/cms/preview-popup.vue")
  },
  {
    name: "cms-preview-popup___sk",
    path: "/sk/cms/preview-popup",
    meta: preview_45popupJtWWWqggPJMeta || {},
    component: () => import("/app/apps/premium/pages/cms/preview-popup.vue")
  },
  {
    name: "cms-preview-popup___sl",
    path: "/sl/cms/preview-popup",
    meta: preview_45popupJtWWWqggPJMeta || {},
    component: () => import("/app/apps/premium/pages/cms/preview-popup.vue")
  },
  {
    name: "cms-preview-popup___sr",
    path: "/sr/cms/preview-popup",
    meta: preview_45popupJtWWWqggPJMeta || {},
    component: () => import("/app/apps/premium/pages/cms/preview-popup.vue")
  },
  {
    name: "cms-preview-popup___tr",
    path: "/tr/cms/preview-popup",
    meta: preview_45popupJtWWWqggPJMeta || {},
    component: () => import("/app/apps/premium/pages/cms/preview-popup.vue")
  },
  {
    name: "cms-preview-popup___el",
    path: "/el/cms/preview-popup",
    meta: preview_45popupJtWWWqggPJMeta || {},
    component: () => import("/app/apps/premium/pages/cms/preview-popup.vue")
  },
  {
    name: "cms-preview-popup___bg",
    path: "/bg/cms/preview-popup",
    meta: preview_45popupJtWWWqggPJMeta || {},
    component: () => import("/app/apps/premium/pages/cms/preview-popup.vue")
  },
  {
    name: "cms-preview-popup___ru",
    path: "/ru/cms/preview-popup",
    meta: preview_45popupJtWWWqggPJMeta || {},
    component: () => import("/app/apps/premium/pages/cms/preview-popup.vue")
  },
  {
    name: "cms-preview___en",
    path: "/en/cms/preview",
    meta: previewePwT9PCja7Meta || {},
    component: () => import("/app/apps/premium/pages/cms/preview.vue")
  },
  {
    name: "cms-preview___de___default",
    path: "/cms/preview",
    meta: previewePwT9PCja7Meta || {},
    component: () => import("/app/apps/premium/pages/cms/preview.vue")
  },
  {
    name: "cms-preview___de",
    path: "/de/cms/preview",
    meta: previewePwT9PCja7Meta || {},
    component: () => import("/app/apps/premium/pages/cms/preview.vue")
  },
  {
    name: "cms-preview___pl",
    path: "/pl/cms/preview",
    meta: previewePwT9PCja7Meta || {},
    component: () => import("/app/apps/premium/pages/cms/preview.vue")
  },
  {
    name: "cms-preview___cs",
    path: "/cs/cms/preview",
    meta: previewePwT9PCja7Meta || {},
    component: () => import("/app/apps/premium/pages/cms/preview.vue")
  },
  {
    name: "cms-preview___et",
    path: "/et/cms/preview",
    meta: previewePwT9PCja7Meta || {},
    component: () => import("/app/apps/premium/pages/cms/preview.vue")
  },
  {
    name: "cms-preview___es",
    path: "/es/cms/preview",
    meta: previewePwT9PCja7Meta || {},
    component: () => import("/app/apps/premium/pages/cms/preview.vue")
  },
  {
    name: "cms-preview___fr",
    path: "/fr/cms/preview",
    meta: previewePwT9PCja7Meta || {},
    component: () => import("/app/apps/premium/pages/cms/preview.vue")
  },
  {
    name: "cms-preview___hr",
    path: "/hr/cms/preview",
    meta: previewePwT9PCja7Meta || {},
    component: () => import("/app/apps/premium/pages/cms/preview.vue")
  },
  {
    name: "cms-preview___it",
    path: "/it/cms/preview",
    meta: previewePwT9PCja7Meta || {},
    component: () => import("/app/apps/premium/pages/cms/preview.vue")
  },
  {
    name: "cms-preview___lv",
    path: "/lv/cms/preview",
    meta: previewePwT9PCja7Meta || {},
    component: () => import("/app/apps/premium/pages/cms/preview.vue")
  },
  {
    name: "cms-preview___lt",
    path: "/lt/cms/preview",
    meta: previewePwT9PCja7Meta || {},
    component: () => import("/app/apps/premium/pages/cms/preview.vue")
  },
  {
    name: "cms-preview___hu",
    path: "/hu/cms/preview",
    meta: previewePwT9PCja7Meta || {},
    component: () => import("/app/apps/premium/pages/cms/preview.vue")
  },
  {
    name: "cms-preview___nl",
    path: "/nl/cms/preview",
    meta: previewePwT9PCja7Meta || {},
    component: () => import("/app/apps/premium/pages/cms/preview.vue")
  },
  {
    name: "cms-preview___pt",
    path: "/pt/cms/preview",
    meta: previewePwT9PCja7Meta || {},
    component: () => import("/app/apps/premium/pages/cms/preview.vue")
  },
  {
    name: "cms-preview___ro",
    path: "/ro/cms/preview",
    meta: previewePwT9PCja7Meta || {},
    component: () => import("/app/apps/premium/pages/cms/preview.vue")
  },
  {
    name: "cms-preview___sk",
    path: "/sk/cms/preview",
    meta: previewePwT9PCja7Meta || {},
    component: () => import("/app/apps/premium/pages/cms/preview.vue")
  },
  {
    name: "cms-preview___sl",
    path: "/sl/cms/preview",
    meta: previewePwT9PCja7Meta || {},
    component: () => import("/app/apps/premium/pages/cms/preview.vue")
  },
  {
    name: "cms-preview___sr",
    path: "/sr/cms/preview",
    meta: previewePwT9PCja7Meta || {},
    component: () => import("/app/apps/premium/pages/cms/preview.vue")
  },
  {
    name: "cms-preview___tr",
    path: "/tr/cms/preview",
    meta: previewePwT9PCja7Meta || {},
    component: () => import("/app/apps/premium/pages/cms/preview.vue")
  },
  {
    name: "cms-preview___el",
    path: "/el/cms/preview",
    meta: previewePwT9PCja7Meta || {},
    component: () => import("/app/apps/premium/pages/cms/preview.vue")
  },
  {
    name: "cms-preview___bg",
    path: "/bg/cms/preview",
    meta: previewePwT9PCja7Meta || {},
    component: () => import("/app/apps/premium/pages/cms/preview.vue")
  },
  {
    name: "cms-preview___ru",
    path: "/ru/cms/preview",
    meta: previewePwT9PCja7Meta || {},
    component: () => import("/app/apps/premium/pages/cms/preview.vue")
  },
  {
    name: "cms-slide___en",
    path: "/en/cms/slide",
    component: () => import("/app/apps/premium/pages/cms/slide.vue")
  },
  {
    name: "cms-slide___de___default",
    path: "/cms/slide",
    component: () => import("/app/apps/premium/pages/cms/slide.vue")
  },
  {
    name: "cms-slide___de",
    path: "/de/cms/slide",
    component: () => import("/app/apps/premium/pages/cms/slide.vue")
  },
  {
    name: "cms-slide___pl",
    path: "/pl/cms/slide",
    component: () => import("/app/apps/premium/pages/cms/slide.vue")
  },
  {
    name: "cms-slide___cs",
    path: "/cs/cms/slide",
    component: () => import("/app/apps/premium/pages/cms/slide.vue")
  },
  {
    name: "cms-slide___et",
    path: "/et/cms/slide",
    component: () => import("/app/apps/premium/pages/cms/slide.vue")
  },
  {
    name: "cms-slide___es",
    path: "/es/cms/slide",
    component: () => import("/app/apps/premium/pages/cms/slide.vue")
  },
  {
    name: "cms-slide___fr",
    path: "/fr/cms/slide",
    component: () => import("/app/apps/premium/pages/cms/slide.vue")
  },
  {
    name: "cms-slide___hr",
    path: "/hr/cms/slide",
    component: () => import("/app/apps/premium/pages/cms/slide.vue")
  },
  {
    name: "cms-slide___it",
    path: "/it/cms/slide",
    component: () => import("/app/apps/premium/pages/cms/slide.vue")
  },
  {
    name: "cms-slide___lv",
    path: "/lv/cms/slide",
    component: () => import("/app/apps/premium/pages/cms/slide.vue")
  },
  {
    name: "cms-slide___lt",
    path: "/lt/cms/slide",
    component: () => import("/app/apps/premium/pages/cms/slide.vue")
  },
  {
    name: "cms-slide___hu",
    path: "/hu/cms/slide",
    component: () => import("/app/apps/premium/pages/cms/slide.vue")
  },
  {
    name: "cms-slide___nl",
    path: "/nl/cms/slide",
    component: () => import("/app/apps/premium/pages/cms/slide.vue")
  },
  {
    name: "cms-slide___pt",
    path: "/pt/cms/slide",
    component: () => import("/app/apps/premium/pages/cms/slide.vue")
  },
  {
    name: "cms-slide___ro",
    path: "/ro/cms/slide",
    component: () => import("/app/apps/premium/pages/cms/slide.vue")
  },
  {
    name: "cms-slide___sk",
    path: "/sk/cms/slide",
    component: () => import("/app/apps/premium/pages/cms/slide.vue")
  },
  {
    name: "cms-slide___sl",
    path: "/sl/cms/slide",
    component: () => import("/app/apps/premium/pages/cms/slide.vue")
  },
  {
    name: "cms-slide___sr",
    path: "/sr/cms/slide",
    component: () => import("/app/apps/premium/pages/cms/slide.vue")
  },
  {
    name: "cms-slide___tr",
    path: "/tr/cms/slide",
    component: () => import("/app/apps/premium/pages/cms/slide.vue")
  },
  {
    name: "cms-slide___el",
    path: "/el/cms/slide",
    component: () => import("/app/apps/premium/pages/cms/slide.vue")
  },
  {
    name: "cms-slide___bg",
    path: "/bg/cms/slide",
    component: () => import("/app/apps/premium/pages/cms/slide.vue")
  },
  {
    name: "cms-slide___ru",
    path: "/ru/cms/slide",
    component: () => import("/app/apps/premium/pages/cms/slide.vue")
  },
  {
    name: "item-slug-details___en",
    path: "/en/item/:slug()/details",
    meta: detailsL6oxkYvYFuMeta || {},
    component: () => import("/app/apps/premium/pages/item/[slug]/details.vue")
  },
  {
    name: "item-slug-details___de___default",
    path: "/artikel/:slug()/einzelheiten",
    meta: detailsL6oxkYvYFuMeta || {},
    component: () => import("/app/apps/premium/pages/item/[slug]/details.vue")
  },
  {
    name: "item-slug-details___de",
    path: "/de/artikel/:slug()/einzelheiten",
    meta: detailsL6oxkYvYFuMeta || {},
    component: () => import("/app/apps/premium/pages/item/[slug]/details.vue")
  },
  {
    name: "item-slug-details___pl",
    path: "/pl/przedmiot/:slug()/detale",
    meta: detailsL6oxkYvYFuMeta || {},
    component: () => import("/app/apps/premium/pages/item/[slug]/details.vue")
  },
  {
    name: "item-slug-details___cs",
    path: "/cs/polozka/:slug()/podrobnosti",
    meta: detailsL6oxkYvYFuMeta || {},
    component: () => import("/app/apps/premium/pages/item/[slug]/details.vue")
  },
  {
    name: "item-slug-details___et",
    path: "/et/uksus/:slug()/uksikasjad",
    meta: detailsL6oxkYvYFuMeta || {},
    component: () => import("/app/apps/premium/pages/item/[slug]/details.vue")
  },
  {
    name: "item-slug-details___es",
    path: "/es/articulo/:slug()/detalles",
    meta: detailsL6oxkYvYFuMeta || {},
    component: () => import("/app/apps/premium/pages/item/[slug]/details.vue")
  },
  {
    name: "item-slug-details___fr",
    path: "/fr/article/:slug()/details",
    meta: detailsL6oxkYvYFuMeta || {},
    component: () => import("/app/apps/premium/pages/item/[slug]/details.vue")
  },
  {
    name: "item-slug-details___hr",
    path: "/hr/artikal/:slug()/pojedinosti",
    meta: detailsL6oxkYvYFuMeta || {},
    component: () => import("/app/apps/premium/pages/item/[slug]/details.vue")
  },
  {
    name: "item-slug-details___it",
    path: "/it/articolo/:slug()/dettagli",
    meta: detailsL6oxkYvYFuMeta || {},
    component: () => import("/app/apps/premium/pages/item/[slug]/details.vue")
  },
  {
    name: "item-slug-details___lv",
    path: "/lv/lieta/:slug()/detalas",
    meta: detailsL6oxkYvYFuMeta || {},
    component: () => import("/app/apps/premium/pages/item/[slug]/details.vue")
  },
  {
    name: "item-slug-details___lt",
    path: "/lt/daiktas/:slug()/detales",
    meta: detailsL6oxkYvYFuMeta || {},
    component: () => import("/app/apps/premium/pages/item/[slug]/details.vue")
  },
  {
    name: "item-slug-details___hu",
    path: "/hu/tetel/:slug()/reszletek",
    meta: detailsL6oxkYvYFuMeta || {},
    component: () => import("/app/apps/premium/pages/item/[slug]/details.vue")
  },
  {
    name: "item-slug-details___nl",
    path: "/nl/item/:slug()/details",
    meta: detailsL6oxkYvYFuMeta || {},
    component: () => import("/app/apps/premium/pages/item/[slug]/details.vue")
  },
  {
    name: "item-slug-details___pt",
    path: "/pt/item/:slug()/detalhes",
    meta: detailsL6oxkYvYFuMeta || {},
    component: () => import("/app/apps/premium/pages/item/[slug]/details.vue")
  },
  {
    name: "item-slug-details___ro",
    path: "/ro/articol/:slug()/detalii",
    meta: detailsL6oxkYvYFuMeta || {},
    component: () => import("/app/apps/premium/pages/item/[slug]/details.vue")
  },
  {
    name: "item-slug-details___sk",
    path: "/sk/polozka/:slug()/podrobnosti",
    meta: detailsL6oxkYvYFuMeta || {},
    component: () => import("/app/apps/premium/pages/item/[slug]/details.vue")
  },
  {
    name: "item-slug-details___sl",
    path: "/sl/postavka/:slug()/podrobnosti",
    meta: detailsL6oxkYvYFuMeta || {},
    component: () => import("/app/apps/premium/pages/item/[slug]/details.vue")
  },
  {
    name: "item-slug-details___sr",
    path: "/sr/predmet/:slug()/detaljima",
    meta: detailsL6oxkYvYFuMeta || {},
    component: () => import("/app/apps/premium/pages/item/[slug]/details.vue")
  },
  {
    name: "item-slug-details___tr",
    path: "/tr/oge/:slug()/detaylar",
    meta: detailsL6oxkYvYFuMeta || {},
    component: () => import("/app/apps/premium/pages/item/[slug]/details.vue")
  },
  {
    name: "item-slug-details___el",
    path: "/el/eidos/:slug()/leptomeries",
    meta: detailsL6oxkYvYFuMeta || {},
    component: () => import("/app/apps/premium/pages/item/[slug]/details.vue")
  },
  {
    name: "item-slug-details___bg",
    path: "/bg/vesh/:slug()/podrobnosti",
    meta: detailsL6oxkYvYFuMeta || {},
    component: () => import("/app/apps/premium/pages/item/[slug]/details.vue")
  },
  {
    name: "item-slug-details___ru",
    path: "/ru/element/:slug()/podrobnosti",
    meta: detailsL6oxkYvYFuMeta || {},
    component: () => import("/app/apps/premium/pages/item/[slug]/details.vue")
  },
  {
    name: "item-slug___en",
    path: "/en/item/:slug()",
    meta: indexMvQlE1AIqkMeta || {},
    component: () => import("/app/apps/premium/pages/item/[slug]/index.vue")
  },
  {
    name: "item-slug___de___default",
    path: "/artikel/:slug()",
    meta: indexMvQlE1AIqkMeta || {},
    component: () => import("/app/apps/premium/pages/item/[slug]/index.vue")
  },
  {
    name: "item-slug___de",
    path: "/de/artikel/:slug()",
    meta: indexMvQlE1AIqkMeta || {},
    component: () => import("/app/apps/premium/pages/item/[slug]/index.vue")
  },
  {
    name: "item-slug___pl",
    path: "/pl/przedmiot/:slug()",
    meta: indexMvQlE1AIqkMeta || {},
    component: () => import("/app/apps/premium/pages/item/[slug]/index.vue")
  },
  {
    name: "item-slug___cs",
    path: "/cs/polozka/:slug()",
    meta: indexMvQlE1AIqkMeta || {},
    component: () => import("/app/apps/premium/pages/item/[slug]/index.vue")
  },
  {
    name: "item-slug___et",
    path: "/et/uksus/:slug()",
    meta: indexMvQlE1AIqkMeta || {},
    component: () => import("/app/apps/premium/pages/item/[slug]/index.vue")
  },
  {
    name: "item-slug___es",
    path: "/es/articulo/:slug()",
    meta: indexMvQlE1AIqkMeta || {},
    component: () => import("/app/apps/premium/pages/item/[slug]/index.vue")
  },
  {
    name: "item-slug___fr",
    path: "/fr/article/:slug()",
    meta: indexMvQlE1AIqkMeta || {},
    component: () => import("/app/apps/premium/pages/item/[slug]/index.vue")
  },
  {
    name: "item-slug___hr",
    path: "/hr/artikal/:slug()",
    meta: indexMvQlE1AIqkMeta || {},
    component: () => import("/app/apps/premium/pages/item/[slug]/index.vue")
  },
  {
    name: "item-slug___it",
    path: "/it/articolo/:slug()",
    meta: indexMvQlE1AIqkMeta || {},
    component: () => import("/app/apps/premium/pages/item/[slug]/index.vue")
  },
  {
    name: "item-slug___lv",
    path: "/lv/lieta/:slug()",
    meta: indexMvQlE1AIqkMeta || {},
    component: () => import("/app/apps/premium/pages/item/[slug]/index.vue")
  },
  {
    name: "item-slug___lt",
    path: "/lt/daiktas/:slug()",
    meta: indexMvQlE1AIqkMeta || {},
    component: () => import("/app/apps/premium/pages/item/[slug]/index.vue")
  },
  {
    name: "item-slug___hu",
    path: "/hu/tetel/:slug()",
    meta: indexMvQlE1AIqkMeta || {},
    component: () => import("/app/apps/premium/pages/item/[slug]/index.vue")
  },
  {
    name: "item-slug___nl",
    path: "/nl/item/:slug()",
    meta: indexMvQlE1AIqkMeta || {},
    component: () => import("/app/apps/premium/pages/item/[slug]/index.vue")
  },
  {
    name: "item-slug___pt",
    path: "/pt/item/:slug()",
    meta: indexMvQlE1AIqkMeta || {},
    component: () => import("/app/apps/premium/pages/item/[slug]/index.vue")
  },
  {
    name: "item-slug___ro",
    path: "/ro/articol/:slug()",
    meta: indexMvQlE1AIqkMeta || {},
    component: () => import("/app/apps/premium/pages/item/[slug]/index.vue")
  },
  {
    name: "item-slug___sk",
    path: "/sk/polozka/:slug()",
    meta: indexMvQlE1AIqkMeta || {},
    component: () => import("/app/apps/premium/pages/item/[slug]/index.vue")
  },
  {
    name: "item-slug___sl",
    path: "/sl/postavka/:slug()",
    meta: indexMvQlE1AIqkMeta || {},
    component: () => import("/app/apps/premium/pages/item/[slug]/index.vue")
  },
  {
    name: "item-slug___sr",
    path: "/sr/predmet/:slug()",
    meta: indexMvQlE1AIqkMeta || {},
    component: () => import("/app/apps/premium/pages/item/[slug]/index.vue")
  },
  {
    name: "item-slug___tr",
    path: "/tr/oge/:slug()",
    meta: indexMvQlE1AIqkMeta || {},
    component: () => import("/app/apps/premium/pages/item/[slug]/index.vue")
  },
  {
    name: "item-slug___el",
    path: "/el/eidos/:slug()",
    meta: indexMvQlE1AIqkMeta || {},
    component: () => import("/app/apps/premium/pages/item/[slug]/index.vue")
  },
  {
    name: "item-slug___bg",
    path: "/bg/vesh/:slug()",
    meta: indexMvQlE1AIqkMeta || {},
    component: () => import("/app/apps/premium/pages/item/[slug]/index.vue")
  },
  {
    name: "item-slug___ru",
    path: "/ru/element/:slug()",
    meta: indexMvQlE1AIqkMeta || {},
    component: () => import("/app/apps/premium/pages/item/[slug]/index.vue")
  },
  {
    name: "item-slug-preview___en",
    path: "/en/item/:slug()/preview",
    meta: previewS7PgymDCArMeta || {},
    component: () => import("/app/apps/premium/pages/item/[slug]/preview.vue")
  },
  {
    name: "item-slug-preview___de___default",
    path: "/item/:slug()/preview",
    meta: previewS7PgymDCArMeta || {},
    component: () => import("/app/apps/premium/pages/item/[slug]/preview.vue")
  },
  {
    name: "item-slug-preview___de",
    path: "/de/item/:slug()/preview",
    meta: previewS7PgymDCArMeta || {},
    component: () => import("/app/apps/premium/pages/item/[slug]/preview.vue")
  },
  {
    name: "item-slug-preview___pl",
    path: "/pl/item/:slug()/preview",
    meta: previewS7PgymDCArMeta || {},
    component: () => import("/app/apps/premium/pages/item/[slug]/preview.vue")
  },
  {
    name: "item-slug-preview___cs",
    path: "/cs/item/:slug()/preview",
    meta: previewS7PgymDCArMeta || {},
    component: () => import("/app/apps/premium/pages/item/[slug]/preview.vue")
  },
  {
    name: "item-slug-preview___et",
    path: "/et/item/:slug()/preview",
    meta: previewS7PgymDCArMeta || {},
    component: () => import("/app/apps/premium/pages/item/[slug]/preview.vue")
  },
  {
    name: "item-slug-preview___es",
    path: "/es/item/:slug()/preview",
    meta: previewS7PgymDCArMeta || {},
    component: () => import("/app/apps/premium/pages/item/[slug]/preview.vue")
  },
  {
    name: "item-slug-preview___fr",
    path: "/fr/item/:slug()/preview",
    meta: previewS7PgymDCArMeta || {},
    component: () => import("/app/apps/premium/pages/item/[slug]/preview.vue")
  },
  {
    name: "item-slug-preview___hr",
    path: "/hr/item/:slug()/preview",
    meta: previewS7PgymDCArMeta || {},
    component: () => import("/app/apps/premium/pages/item/[slug]/preview.vue")
  },
  {
    name: "item-slug-preview___it",
    path: "/it/item/:slug()/preview",
    meta: previewS7PgymDCArMeta || {},
    component: () => import("/app/apps/premium/pages/item/[slug]/preview.vue")
  },
  {
    name: "item-slug-preview___lv",
    path: "/lv/item/:slug()/preview",
    meta: previewS7PgymDCArMeta || {},
    component: () => import("/app/apps/premium/pages/item/[slug]/preview.vue")
  },
  {
    name: "item-slug-preview___lt",
    path: "/lt/item/:slug()/preview",
    meta: previewS7PgymDCArMeta || {},
    component: () => import("/app/apps/premium/pages/item/[slug]/preview.vue")
  },
  {
    name: "item-slug-preview___hu",
    path: "/hu/item/:slug()/preview",
    meta: previewS7PgymDCArMeta || {},
    component: () => import("/app/apps/premium/pages/item/[slug]/preview.vue")
  },
  {
    name: "item-slug-preview___nl",
    path: "/nl/item/:slug()/preview",
    meta: previewS7PgymDCArMeta || {},
    component: () => import("/app/apps/premium/pages/item/[slug]/preview.vue")
  },
  {
    name: "item-slug-preview___pt",
    path: "/pt/item/:slug()/preview",
    meta: previewS7PgymDCArMeta || {},
    component: () => import("/app/apps/premium/pages/item/[slug]/preview.vue")
  },
  {
    name: "item-slug-preview___ro",
    path: "/ro/item/:slug()/preview",
    meta: previewS7PgymDCArMeta || {},
    component: () => import("/app/apps/premium/pages/item/[slug]/preview.vue")
  },
  {
    name: "item-slug-preview___sk",
    path: "/sk/item/:slug()/preview",
    meta: previewS7PgymDCArMeta || {},
    component: () => import("/app/apps/premium/pages/item/[slug]/preview.vue")
  },
  {
    name: "item-slug-preview___sl",
    path: "/sl/item/:slug()/preview",
    meta: previewS7PgymDCArMeta || {},
    component: () => import("/app/apps/premium/pages/item/[slug]/preview.vue")
  },
  {
    name: "item-slug-preview___sr",
    path: "/sr/item/:slug()/preview",
    meta: previewS7PgymDCArMeta || {},
    component: () => import("/app/apps/premium/pages/item/[slug]/preview.vue")
  },
  {
    name: "item-slug-preview___tr",
    path: "/tr/item/:slug()/preview",
    meta: previewS7PgymDCArMeta || {},
    component: () => import("/app/apps/premium/pages/item/[slug]/preview.vue")
  },
  {
    name: "item-slug-preview___el",
    path: "/el/item/:slug()/preview",
    meta: previewS7PgymDCArMeta || {},
    component: () => import("/app/apps/premium/pages/item/[slug]/preview.vue")
  },
  {
    name: "item-slug-preview___bg",
    path: "/bg/item/:slug()/preview",
    meta: previewS7PgymDCArMeta || {},
    component: () => import("/app/apps/premium/pages/item/[slug]/preview.vue")
  },
  {
    name: "item-slug-preview___ru",
    path: "/ru/item/:slug()/preview",
    meta: previewS7PgymDCArMeta || {},
    component: () => import("/app/apps/premium/pages/item/[slug]/preview.vue")
  },
  {
    name: "login___en",
    path: "/en/login",
    component: () => import("/app/apps/premium/pages/login.vue")
  },
  {
    name: "login___de___default",
    path: "/login",
    component: () => import("/app/apps/premium/pages/login.vue")
  },
  {
    name: "login___de",
    path: "/de/login",
    component: () => import("/app/apps/premium/pages/login.vue")
  },
  {
    name: "login___pl",
    path: "/pl/login",
    component: () => import("/app/apps/premium/pages/login.vue")
  },
  {
    name: "login___cs",
    path: "/cs/login",
    component: () => import("/app/apps/premium/pages/login.vue")
  },
  {
    name: "login___et",
    path: "/et/login",
    component: () => import("/app/apps/premium/pages/login.vue")
  },
  {
    name: "login___es",
    path: "/es/login",
    component: () => import("/app/apps/premium/pages/login.vue")
  },
  {
    name: "login___fr",
    path: "/fr/login",
    component: () => import("/app/apps/premium/pages/login.vue")
  },
  {
    name: "login___hr",
    path: "/hr/login",
    component: () => import("/app/apps/premium/pages/login.vue")
  },
  {
    name: "login___it",
    path: "/it/login",
    component: () => import("/app/apps/premium/pages/login.vue")
  },
  {
    name: "login___lv",
    path: "/lv/login",
    component: () => import("/app/apps/premium/pages/login.vue")
  },
  {
    name: "login___lt",
    path: "/lt/login",
    component: () => import("/app/apps/premium/pages/login.vue")
  },
  {
    name: "login___hu",
    path: "/hu/login",
    component: () => import("/app/apps/premium/pages/login.vue")
  },
  {
    name: "login___nl",
    path: "/nl/login",
    component: () => import("/app/apps/premium/pages/login.vue")
  },
  {
    name: "login___pt",
    path: "/pt/login",
    component: () => import("/app/apps/premium/pages/login.vue")
  },
  {
    name: "login___ro",
    path: "/ro/login",
    component: () => import("/app/apps/premium/pages/login.vue")
  },
  {
    name: "login___sk",
    path: "/sk/login",
    component: () => import("/app/apps/premium/pages/login.vue")
  },
  {
    name: "login___sl",
    path: "/sl/login",
    component: () => import("/app/apps/premium/pages/login.vue")
  },
  {
    name: "login___sr",
    path: "/sr/login",
    component: () => import("/app/apps/premium/pages/login.vue")
  },
  {
    name: "login___tr",
    path: "/tr/login",
    component: () => import("/app/apps/premium/pages/login.vue")
  },
  {
    name: "login___el",
    path: "/el/login",
    component: () => import("/app/apps/premium/pages/login.vue")
  },
  {
    name: "login___bg",
    path: "/bg/login",
    component: () => import("/app/apps/premium/pages/login.vue")
  },
  {
    name: "login___ru",
    path: "/ru/login",
    component: () => import("/app/apps/premium/pages/login.vue")
  }
]