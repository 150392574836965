import type { AuctionCar } from '@autobid/ui/types/Car'
import { getSingleAuctionQueryFields } from '@autobid/ui/utils/auctions/getSingleAuctionQueryFields'
import type { CarQueryProps } from '@autobid/ui/utils/car/query/getQueryField'
import { getQueryField } from '@autobid/ui/utils/car/query/getQueryField'

type Props = CarQueryProps & {
  bidLimit: number
  auctionSeparated?: boolean
  omitCounters?: boolean
}

export const barometerMissingFieldsInfo = (
  lang: string,
  data?: Partial<AuctionCar> | null
) => {
  const potentialMissingDetailsFields = [
    'state',
    'auctionId',
    'dataAddition',
    'supplier',
    'publicationInfo',
    'lowBudgetWarning',
    'hotbidStartTime',
    'ranking',
    'manufacturer',
    'inActiveHotbidPhase',
    'hotbidDuration',
    'bidStep',
    'documentGroups',
    'goft',
    'bidOptions',
    'ownBidAgentAmount'
  ]

  let missingFields = potentialMissingDetailsFields
    .filter((el) => !data || !(el in data))
    .map((el) => getQueryField(el, lang))
    .join(' ')

  if (missingFields.length) {
    missingFields += ' bids'
  }

  return { missingFields }
}

export const getBarometerQuery = ({
  lang,
  locales,
  bidLimit,
  currentData,
  ignoreCache,
  omitAuction,
  omitCounters,
  auctionSeparated
}: Props) => {
  let itemDataFields = `
    id
    appId
    slug
    ${getQueryField('lowBudgetWarning')}
    ${getQueryField('auctionId')}
    stage
    ${getQueryField('state')}
    bids
    ${getQueryField('bidStep')}
    hasNote
    equipments
    ${getQueryField('ranking')}
    ${getQueryField('hotbidStartTime')}
    auctionStartDate
    ${getQueryField('inActiveHotbidPhase')}
    ${getQueryField('hotbidDuration')}
    ${getQueryField('bidOptions')}
    name
    catalogNumber
    ${getQueryField('manufacturer')}
    price {
      start
      minimal
      current
    }
    imageGroups {
      itemMainImageSubgroup {
        links {
          hd
        }
        category
        description {
          categoryLabel
        }
      }
    }
    category {
      id
      name
    }
    ${getQueryField('dataAddition')}
    hasBid
    isWatcher
    isHighestBidder
    status {
      bmwBankFinancingAvailable
      bank11FinancingAvailable
      transportAvailable
    }
    ${getQueryField('supplier')}
    taxInformation
    additionalInformation {
      itemLocationCode
      itemLocationCountry {
        name
        vatRate
      }
    }
    ${getQueryField('ownBidAgentAmount')}
    ${getQueryField('documentGroups')}
    ${getQueryField('publicationInfo')}
    ${getQueryField('goft', lang)}
  `

  if (currentData && !ignoreCache) {
    const { missingFields } = barometerMissingFieldsInfo(lang, currentData)

    itemDataFields = missingFields ?? ''

    if (itemDataFields.length) {
      itemDataFields += ' id'
    }
  }

  const carsListAlreadyFetched = currentData && 'hasCarsList' in currentData
  const shouldAuctionBeFetched = ignoreCache || !omitAuction

  return `
  query barometer($id: Int, $auctionId: Int, $lang: String! = "DE") {
    singleItem(
      params: {
        id: $id, 
        auctionId: $auctionId, 
        bidParams: {limit: ${bidLimit}}, 
        lang: $lang, 
        metadataParams: {counters: ${
          carsListAlreadyFetched || omitCounters ? 'false' : 'true'
        }}
      }
    ) {
      ${
        currentData &&
        'pagination' in currentData &&
        !('details' in currentData)
          ? ''
          : 'metadata'
      }
      item {
        ${itemDataFields ? `itemData { ${itemDataFields}}` : ''}
        ${
          shouldAuctionBeFetched && !auctionSeparated
            ? `auctionData { ${getSingleAuctionQueryFields(lang, locales)} }`
            : ''
        }
      }
    }
    ${
      shouldAuctionBeFetched && auctionSeparated
        ? `
      singleAuction(params: {lang: $lang, id: $auctionId}) {
        item { ${getSingleAuctionQueryFields(lang, locales)} }
      }`
        : ''
    }
    
  }`
}
