<template>
  <common-pdf-activator v-if="isPdfLink" :href="finalHref">
    <slot />
  </common-pdf-activator>

  <component
    :is="finalHref?.length ? 'a' : 'span'"
    v-else-if="
      isPopupLink ||
      !finalHref ||
      as === 'a' ||
      myTarget === '_blank' ||
      isRelativeAndOtherLocale
    "
    v-bind="{ href: finalHref, target: myTarget }"
    data-testid="external-link"
    :class="{
      [UNDERLINE_HOVER_STYLES]: underline
    }"
  >
    <slot />
  </component>

  <nuxt-link
    v-else
    :href="finalHref"
    data-testid="internal-link"
    :class="{
      [UNDERLINE_HOVER_STYLES]: underline
    }"
  >
    <slot />
  </nuxt-link>
</template>

<script lang="ts" setup>
import { UNDERLINE_HOVER_STYLES } from '@autobid/ui/constants/UNDERLINE_HOVER_STYLES'
import { computed } from 'vue'

interface Props {
  as?: 'a' | 'nuxt-link'
  href?: string
  target?: string
  /** display underline on hover */
  underline?: boolean
}

const props = defineProps<Props>()

const { locale, locales } = useI18n()
const domain = new URL(useRuntimeConfig().public.SELF_URL)
const linkDomain =
  props?.href && props.href.startsWith('http') ? new URL(props.href) : null
const myTarget = computed(() => {
  if (props.target) {
    return props.target
  }

  return props.href?.includes('http') && !props.href?.includes(domain.hostname)
    ? '_blank'
    : undefined
})

const finalHref = computed(() => {
  if (
    !linkDomain ||
    linkDomain.hostname !== domain.hostname ||
    myTarget.value === '_blank' ||
    !props.href?.includes('http')
  ) {
    return props.href
  }

  const parseToRelativeUrl = (url: string) => {
    const urlObj = new URL(url)
    return `${urlObj.pathname}${urlObj.search}${urlObj.hash}`
  }

  return parseToRelativeUrl(props.href.toString())
})

const isRelativeAndOtherLocale = computed(() => {
  if (!finalHref.value || finalHref.value.startsWith('http')) return false

  const linkLocale = finalHref.value.split('/').filter(Boolean)?.[0]

  return (
    locale.value !== linkLocale &&
    locales.value
      .map((locale) => locale.code.toLowerCase())
      .includes(linkLocale)
  )
})

const isPdfLink = finalHref.value?.includes('cms/pdf')
const isPopupLink = finalHref.value?.includes('#popup-')
</script>
