<template>
  <div
    ref="wrapper"
    :aria-required="context.state.required ? 'true' : 'false'"
    class="date-picker"
  >
    <DatePicker
      :id="context.id"
      class="relative z-10 [&>div>div>input]:bg-transparent"
      :value="context.value"
      :placeholder="context.placeholder"
      :max-date="maxDate"
      :min-date="minDate"
      :time-picker-enabled="false"
      @change="handleChange"
      @open="toggleLabel(true)"
      @close="toggleLabel(false)"
    />
  </div>
</template>

<script setup lang="ts">
import type { FormKitSchemaContext } from '@formkit/core'
import DatePicker from './DatePicker.vue'

interface Props {
  context: {
    value: string
    label?: string
    placeholder?: string
    minDate: string // today or specific date in format yyyy-mm-dd
    maxDate: string // today or specific date in format yyyy-mm-dd
    node: FormKitSchemaContext & {
      name?: string
      input: (value: string) => void
    }
    id: string
    state: {
      required: boolean
    }
  }
}
const props = defineProps<Props>()

const wrapper = ref<HTMLDivElement>()
const getDate = (date: string | undefined) => {
  if (!date) return

  if (date === 'today') return new Date()

  try {
    return new Date(date)
  } catch {
    return undefined
  }
}

const maxDate = getDate(props.context.maxDate)
const minDate = getDate(props.context.minDate)

const toggleLabel = (value: boolean) => {
  wrapper.value
    .closest('.formkit-outer')
    ?.setAttribute('data-expanded', `${value}`)
}

const handleChange = (value: string) => {
  props.context.node.input(value)
  props.context.node.context?.handlers.blur()
}
</script>
