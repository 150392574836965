import { defineStore } from 'pinia'
import { ref } from 'vue'

export type RequestError = {
  code: number
  message?: string
}

export const useAppStore = defineStore('useApp', () => {
  const requestError = ref<RequestError | null>(null)
  const appBrowserActive = ref(false)
  const loading = ref<boolean | string>(false) // provide a string to replace a text "loading..." with your custom text

  return {
    requestError,
    appBrowserActive,
    loading
  }
})
