<template>
  <NotificationsListElement
    v-if="hasMoreNotifications && !fetchingFailed"
    :unread="false"
    as="div"
    data-testid="notifications-load-more"
    @click="loadMore"
  >
    <div class="flex items-center justify-center text-center">
      <span :class="{ 'opacity-50': loading.notifications }">
        {{ $t('header.notifications-center.load-more') }}
      </span>

      <Icon
        v-if="loading.notifications"
        mode="svg"
        data-testid="load-more-loading"
        name="eos-icons:loading"
        class="ml-1 h-4 w-4"
      />
    </div>
  </NotificationsListElement>
</template>

<script lang="ts" setup>
import { useNotificationStore } from '@autobid/nuxt-pinia-store/store/useNotificationStore'
import { storeToRefs } from 'pinia'
import NotificationsListElement from './NotificationsListElement.vue'

const { notifications, loading, hasMoreNotifications, fetchingFailed } =
  storeToRefs(useNotificationStore())
const { getNotifications } = useNotificationStore()

const loadMore = () => {
  getNotifications({
    limit: '50',
    offset: notifications.value.length.toString()
  })
}
</script>
