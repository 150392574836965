import { isOtherDomain } from '@autobid/ui/utils/isOtherDomain'
import { parseLinkToRelative } from '@autobid/ui/utils/parseLinkToRelative'

export const useOpenLink = () => {
  const { push } = useRouter()

  const open = (link: string, otherDomainInNewTab?: boolean) => {
    if (isOtherDomain(link)) {
      navigateTo(link, {
        external: true,
        open: otherDomainInNewTab
          ? {
              target: '_blank'
            }
          : undefined
      })
    } else {
      // get a path from the absolute link
      const path = parseLinkToRelative(link)
      const hash = path.match(/#(.*)$/)?.[1] || undefined

      push({ path, hash: hash ? `#${hash}` : undefined })
    }
  }

  return { open }
}
