<template>
  <CommonLink
    :href="`/${locale}${href || 'search'}?${params}&${sortingType}`"
    class="flex h-full w-full items-center justify-between border-b border-b-[#E6E6E6] py-1 hover:bg-slate-100"
    @click="prefetchDictionary"
    @mouseover="prefetchDictionary"
  >
    <div class="flex w-full items-center justify-between">
      <div
        class="flex w-full items-center break-keep text-sm text-card-primary"
      >
        <Icon
          mode="svg"
          name="ic:baseline-chevron-right"
          class="-ml-1 -mr-0.5 text-xl"
        />
        <span class="!w-[80%] truncate whitespace-nowrap pr-1" :title="_name"
          >{{ _name }}
        </span>
        <p class="ml-auto text-[#3C3C3B]">{{ count }}</p>
      </div>
    </div>
  </CommonLink>
</template>

<script lang="ts" setup>
import { SORTING_TYPES } from '@autobid/nuxt-pinia-store/consts/sortingTypes'
import { usePrefetchDictionary } from '@autobid/ui/composables/useDictionary'

const prefetchDictionary = usePrefetchDictionary()

const { locale, t } = useI18n()

interface Props {
  name: string
  count: number
  href?: string
  value?: string | number
  type: 'brandId-brand' | 'category-categoryId' | 'e223' | 'e17'
}

const props = defineProps<Props>()
const _name =
  props.name === 'search-card.more' ? t('search-card.more') : props.name

const VALUE_DELIMITER = ','
const sortingType = new URLSearchParams({ sortingType: SORTING_TYPES[0] })
const params =
  typeof props.value === 'string' && props.value?.includes(VALUE_DELIMITER)
    ? props.value
        .split(VALUE_DELIMITER)
        .map((loopValue) => `${props.type}=${loopValue}`)
        .join('&')
    : `${props.type}=${props.value}`
</script>
