<template>
  <VueDatePicker
    :id="id"
    v-model="date"
    :locale="lang"
    :placeholder="placeholder"
    :min-date="minDate"
    :max-date="maxDate"
    model-type="dd-MM-yyyy"
    :format="formatDate"
    :auto-apply="true"
    :enable-time-picker="timePickerEnabled"
    class="[&>div>div>input]:text-base [&>div>div>input]:text-primary"
    @update:model-value="$emit('change', date)"
    @open="$emit('open')"
    @closed="$emit('close')"
  ></VueDatePicker>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import { getIsoLang } from '@autobid/ui/utils/langUtils'
import { formatDate } from '@autobid/ui/utils/date/formatDate'

interface Props {
  id?: string
  value: string
  placeholder?: string
  minDate?: Date
  maxDate?: Date
  timePickerEnabled?: boolean
}

interface Emits {
  (e: 'change', value: string): void
  (e: 'open'): void
  (e: 'close'): void
}

defineEmits<Emits>()

const props = defineProps<Props>()
const { locale } = useI18n()
const date = ref(props.value || '')
const lang = `${locale.value}-${getIsoLang(locale.value).toUpperCase()}`
</script>

<style lang="scss">
.dp__overlay_cell_active,
.dp__range_end,
.dp__range_start,
.dp__active_date {
  background: var(--color-primary);
  color: var(--color-on-primary);
}

.dp__month_year_wrap {
  button {
    &:hover {
      color: var(--color-primary);
      font-weight: 700;
    }
  }
}

.dp__input_wrap {
  input {
    border: none;
    font-size: 14px;
    min-height: 40px;
  }
}
</style>
