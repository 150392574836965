import type { RouterConfig } from '@nuxt/schema'

// https://router.vuejs.org/api/#routeroptions
export const getRouterConfig = (): RouterConfig => {
  return {
    scrollBehavior(to, from, savedPosition) {
      return new Promise((resolve, _reject) => {
        setTimeout(
          async () => {
            if (to.path === from.path) {
              resolve()
            } else if (savedPosition) {
              resolve(savedPosition)
            } else if (to.hash && from?.name) {
              let element: HTMLElement

              // wait till element is rendered - e.g. for case of dynamic components
              for (let timeleft = 1500; timeleft > 0; timeleft -= 100) {
                element = document.querySelector(to.hash)
                if (element) break

                await new Promise((resolve) => {
                  setTimeout(() => {
                    resolve(true)
                  }, 100)
                })
              }

              resolve(
                element
                  ? {
                      el: to.hash,
                      top: 80
                    }
                  : { top: 0 }
              )
            } else {
              resolve({ top: 0 })
            }
          },
          to.meta.pageTransition ? 250 : 100
        )
      })
    }
  }
}
