import { useAppStore } from '@autobid/nuxt-pinia-store/store/useAppStore'
import type { NitroFetchOptions } from 'nitropack'
import * as Sentry from '@sentry/vue'
import { storeToRefs } from 'pinia'

type HttpError = {
  message: string
  stack: string
  statusCode: number
  statusMessage: string
  url: string
}

type QueryBasicBodyData = {
  queryApi: string
  queryUrl: string
} & Record<string, unknown>

const getRequestId = () => {
  try {
    return crypto.randomUUID()
  } catch {
    return `${new Date().getTime()}-${Math.round(Math.random() * 100)}`
  }
}

export const useCustomFetch = () => {
  const { requestError } = storeToRefs(useAppStore())
  const ipAddressHeader = useRequestHeaders(['x-forwarded-for'])

  const onApiRequestError = (error?: HttpError) => {
    if (!error || String(error).includes('cloudflare')) return
    if (error.statusCode === 503) {
      return void window.location.reload()
    }

    const { statusCode, statusMessage, message } = error
    const errorMessage = statusMessage?.length ? statusMessage : message ?? ''

    const tokenMismatch =
      statusCode === 403 &&
      errorMessage.toLowerCase().includes('app csrf token mismatch')

    const showDialog =
      (tokenMismatch || statusCode === 401 || statusCode === 419) &&
      process.client

    const setMessageFromI18n = tokenMismatch || statusCode === 401

    const newRequestError = {
      code: statusCode,
      message: setMessageFromI18n ? undefined : errorMessage
    }

    if (showDialog) {
      requestError.value = newRequestError
    }

    if (tokenMismatch) return

    const isAPIDown = errorMessage === 'fetch failed'
    const unableToFetch = errorMessage.toLowerCase().includes('failed to fetch')

    if (process.client && (statusCode === 408 || unableToFetch || isAPIDown)) {
      requestError.value = { code: navigator.onLine ? 503 : 408 }
      return
    }

    throw createError({
      statusCode,
      statusMessage: errorMessage
    })
  }

  const $customFetch = async <T>(
    url: string,
    opts?: NitroFetchOptions<'get' | 'patch' | 'post' | 'put' | 'delete'> & {
      body?: Record<string, unknown>
    }
  ) => {
    const hasBody = opts?.body
    if (hasBody) {
      opts.body.headers = {
        ...(opts.body.headers ?? {}),
        'API-REQUEST-ID': getRequestId()
      }
    }

    const result = await $fetch<T>(url, {
      ...(opts ?? {}),
      headers: { ...ipAddressHeader, ...(opts?.headers ?? {}) }
    }).catch((error) => {
      // all errors has the same scheme because are opearted by proxy, the optional chaining is only for unit test purpose
      onApiRequestError(error.data ?? error)
    })

    const body = opts
      ? hasBody
        ? (opts.body as QueryBasicBodyData)
        : undefined
      : undefined

    if (Sentry && typeof Sentry.addBreadcrumb === 'function') {
      Sentry?.addBreadcrumb({
        type: 'http',
        category: 'fetch',
        message: url,
        level: 'info',
        data: {
          api: body?.queryApi ?? undefined,
          endpoint: body?.queryUrl ?? undefined,
          request: {
            ...(body ?? {}),
            queryApi: undefined,
            queryUrl: undefined
          },
          response: result
        }
      })
    }

    return result || null
  }

  return { $customFetch }
}
