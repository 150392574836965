<template>
  <common-dialog
    :opened="requestError !== null && !forceClose"
    @close="handleClose"
  >
    <template #title>
      <p v-if="requestError" class="text-center">
        <span v-if="requestError.message">
          {{ $t('request-error-dialog.error') }} {{ requestError.code }}
        </span>
        <span v-else>
          {{ $t(`request-error-dialog.${requestError.code}.title`) }}
        </span>
      </p>
    </template>
    <template #content>
      <p v-if="requestError" class="text-center">
        <span v-if="requestError.message">
          {{ $t('request-error-dialog.error-occurred') }}:
          {{ requestError.message }}
        </span>
        <span v-else>
          {{ $t(`request-error-dialog.${requestError.code}.content`) }}
        </span>
      </p>
    </template>
    <template #buttons>
      <div
        v-if="requestError"
        class="flex flex-wrap items-center justify-center gap-3"
      >
        <elements-button
          v-if="[408, 419, 503].includes(requestError.code)"
          class="rounded-md"
          @click="reloadPage"
        >
          {{ $t('request-error-dialog.reload-page') }}
        </elements-button>
        <elements-button
          v-else-if="hasNot401RequestErrorMessage"
          class="rounded-md"
          @click="goHome"
        >
          {{ $t('error-page.404.go-home-btn') }}
        </elements-button>
        <template v-else>
          <elements-button
            v-if="requestError.code === 401"
            class="rounded-md"
            @click="signIn"
          >
            {{ $t('request-error-dialog.401.button.yes') }}
          </elements-button>

          <elements-button
            color="secondary"
            class="rounded-md"
            @click="handleClose"
          >
            {{
              $t(
                requestError.code === 401
                  ? 'request-error-dialog.401.button.no'
                  : 'request-error-dialog.403.button.ok'
              )
            }}
          </elements-button>
        </template>
      </div>
    </template>
  </common-dialog>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import { useAppStore } from '@autobid/nuxt-pinia-store/store/useAppStore'
import { useAutobidAuth } from '@autobid/nuxt-auth/src/composables/useAutobidAuth'
import { useCustomFetch } from '@autobid/ui/composables/useHttp'

const { locale } = useI18n()
const { signIn, signOut } = useAutobidAuth()
const { requestError } = storeToRefs(useAppStore())
const { $customFetch } = useCustomFetch()
const forceClose = ref(false)
const hasNot401RequestErrorMessage = computed(
  () => requestError.value?.code !== 401 && requestError.value?.message
)

const reloadPage = () => {
  window.location.reload()
}

const goHome = () => {
  window.location.href = `/${locale.value}`
}

const handleClose = async () => {
  if (!hasNot401RequestErrorMessage.value) {
    switch (requestError.value.code) {
      case 401:
        signOut(true)
        break
      case 403:
        await $customFetch('/api/destroy-session', { method: 'POST' })
        window.location.reload()
        break
    }
  }

  forceClose.value = true

  setTimeout(() => {
    // wait till animation end
    requestError.value = null
    forceClose.value = false
  }, 300)
}
</script>
