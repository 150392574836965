<template>
  <div class="language-switcher-inline">
    <LazyLayoutLanguageSwitcherTypeDropdown
      v-if="resolution.width < 1024"
      :locales="locales"
      :x="x"
      :y="y"
    />

    <div v-else class="flex gap-2.5 pr-8">
      <CurrentLanguage
        class="relative after:absolute after:left-0 after:top-0 after:z-10 after:h-full after:w-full after:rounded-full after:bg-black after:opacity-65"
      />

      <div v-for="lang in locales" :key="lang.id" class="block">
        <LayoutLanguageSwitcherLink v-bind="lang" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useBodyStore } from '@autobid/nuxt-pinia-store/store/useBodyStore'
import CurrentLanguage from '@autobid/ui/components/layout/uiNavigation/partials/CurrentLanguage.vue'
import { storeToRefs } from 'pinia'
import type { LanguageSwitcherLocale } from '@autobid/ui/types/components/LanguageSwitcher'

interface Props {
  locales: LanguageSwitcherLocale[]
  x: 'left' | 'right'
  y: 'top' | 'bottom'
}

defineProps<Props>()

const { resolution } = storeToRefs(useBodyStore())
</script>

<style lang="scss">
@media (min-width: 1024px) {
  .language-switcher-inline {
    span {
      display: flex;
    }

    a {
      &:hover {
        transform: scale(110%);
      }
    }

    svg {
      width: 35px;
      min-width: 35px;
      height: 35px;
    }
  }
}

.language-switcher-inline {
  display: flex;

  a {
    padding: 0;

    &:hover {
      background: transparent;
    }
  }

  .iconify {
    margin: 0;
    width: 27px;
    min-width: 27px;
    height: 27px;
    border: 0;

    & + span {
      display: none;
    }
  }
}
</style>
