<template>
  <li
    class="relative cursor-default select-none py-2 pl-10 pr-4"
    :class="{
      'bg-primary text-white': active,
      'text-gray-900': !active
    }"
  >
    <span
      class="block truncate"
      :class="{
        'font-medium': selected,
        'font-normal': !selected
      }"
    >
      {{ getOptionDisplayValue(option) }}
    </span>
    <span
      v-if="selected"
      class="absolute inset-y-0 left-0 flex items-center pl-3"
      :class="{ 'text-white': active, 'text-primary': !active }"
    >
      <Icon
        mode="svg"
        name="material-symbols:check-small-rounded"
        class="h-5 w-5"
        aria-hidden="true"
      />
    </span>
  </li>
</template>

<script setup lang="ts">
import type { SelectOption } from '@autobid/ui/types/components/Select'

type Props = {
  selected: boolean
  active: boolean
  option: SelectOption
}

defineProps<Props>()

const getOptionDisplayValue: (item: SelectOption) => string = inject(
  'getOptionDisplayValue'
)
</script>
