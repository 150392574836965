<template>
  <Select
    :value="context.value"
    :placeholder="context.placeholder"
    :input-id="context.id"
    :options="context.options"
    :multiple="context.multiple"
    :disable-search="context.disableSearch"
    :required="context.state.required"
    @change="handleChange"
  />
</template>

<script setup lang="ts">
import type { FormKitSchemaContext } from '@formkit/core'
import type {
  SelectOption,
  SelectOptions
} from '@autobid/ui/types/components/Select'
import Select from './Select.vue'

interface Props {
  context: {
    value: SelectOption[] | SelectOption
    label?: string
    multiple?: boolean
    disableSearch?: boolean
    options?: SelectOption[]
    placeholder?: string
    state: {
      required: boolean
    }
    node: FormKitSchemaContext & {
      name?: string
      input: (value: boolean) => void
    }
    id: string
  }
}

const props = defineProps<Props>()

const handleChange = (value: SelectOptions) => {
  props.context.node.input(value)
  props.context.node.context?.handlers.blur()
}
</script>
