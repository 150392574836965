import { useCustomFetch } from '@autobid/ui/composables/useHttp'
import { useQuery } from '@tanstack/vue-query'

type Country = {
  id: number
  name: string
  isoCode: string
  autobidCode: string
  vatRate: number
  euMember: boolean
}

type Resp = {
  data?: {
    countries: {
      items: Country[]
    }
  }
}

export const useCountries = () => {
  const headers = useRequestHeaders(['cookie'])
  const { locale } = useI18n()
  const { $customFetch } = useCustomFetch()

  const fetchFn = async () => {
    const resp = await $customFetch<Resp>(
      `/api/countries?lang=${locale.value}`,
      headers
    )

    return resp.data?.countries.items || []
  }

  return useQuery(['countries', locale.value], fetchFn, {
    refetchOnWindowFocus: false
  })
}
