<template>
  <div
    ref="expanderNode"
    class="expander relative z-10 overflow-hidden duration-300"
    :style="{
      maxHeight: maxHeight ? `${maxHeight}px` : undefined
    }"
  >
    <slot />

    <div
      v-if="expander && !active && expander.height"
      class="expander-overlay absolute inset-x-0 bottom-0 block text-4xl md:hidden"
    >
      <div
        class="absolute inset-x-0 bottom-0 z-10 h-16 bg-gradient-to-t to-transparent opacity-75"
        role="presentation"
        :class="[
          Boolean(expander.backgroundBlurred) ? 'backdrop-blur-sm' : undefined,
          expanderCva({
            backgroundColor: expander.backgroundColor || undefined
          })
        ]"
      ></div>

      <button
        class="relative z-20 w-full text-center"
        :class="[
          expanderCva({
            iconColor: expander.iconColor || undefined
          })
        ]"
        @click="handleExpand"
      >
        <Icon mode="svg" name="mdi:chevron-down" />
      </button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { ExpanderElement } from '@autobid/strapi-integration/typescript/strapi/elements/Expander'
import { expanderCva } from '@autobid/ui/utils/cva/expanderCva'

interface Props {
  expander?: ExpanderElement
}

const props = defineProps<Props>()

const active = ref(false)
const expanderNode = ref<HTMLDivElement>()
const maxHeight = ref(props.expander?.height ?? 0)
const EXPAND_DURATION = 300

const handleExpand = () => {
  active.value = true
  maxHeight.value = expanderNode.value.scrollHeight

  setTimeout(() => {
    maxHeight.value = 0
  }, EXPAND_DURATION)
}

onMounted(() => {
  if (expanderNode.value?.scrollHeight <= props.expander?.height) {
    active.value = true
  }
})
</script>

<style lang="scss" scoped>
@media (min-width: 768px) {
  .expander {
    max-height: unset !important;
  }
}
</style>
